import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './RadioButton.css';

type Props = {
	title: string;
	titleSize?: number;
	radioSize?: number;
	onClick?: (i: number) => void;
	index: number;
	selIndex: number;
};

export type RadioButtonHandle = {
	getValue: () => boolean;
};

const RadioButton = forwardRef<RadioButtonHandle, Props>((props, ref) => {
	const chbRef = useRef<HTMLInputElement>(null);

	const handleRadioChange = () => {
		if (props.onClick) {
			props.onClick(props.index);
		}
	};

	return (
		<div className='radio-button' onClick={handleRadioChange}>
			<label className='radio-button-title' style={{fontSize: props.titleSize}}>{props.title}</label>
			<input
				ref={chbRef}
				style={{width: props.radioSize}}
				type='radio'
				value={`radio-${props.index}`}
				checked={props.index === props.selIndex}
				onChange={handleRadioChange}
			/>
		</div>

	);
});

RadioButton.displayName = 'RadioButton';

export default RadioButton;
