import React, {useRef, useState} from 'react';
import './AccessDenied.css';
import Space from '../../components/Space/Space';
import Lock from '../../assets/icons/lock';

const AccessDenied = () => {
	// Assets

	const lockSvg: Lock = new Lock();

	return (
		<div className='AccessDenied'>
			<div className='access-den-cont'>
				<img className='acc-den-img' src={lockSvg.normal()}></img>
				<p className='acc-den-title'>Access Denied</p>
				<div>
					<Space size={20} isAutoResize={false}></Space>
					<p className='acc-den-owner-note'>Your account is waiting for approval from the business admin. Please contact your employer.</p>
				</div>
			</div>
		</div >
	);
};

export default AccessDenied;
