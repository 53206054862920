class PasteSvg {
	svgRaw = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M17.7884 13.8495V19.1861C17.7884 23.6332 16.0096 25.412 11.5625 25.412H6.22595C1.77884 25.412 0 23.6332 0 19.1861V13.8495C0 9.40244 1.77884 7.6236 6.22595 7.6236H11.5625C16.0096 7.6236 17.7884 9.40244 17.7884 13.8495Z" fill="#292D32"/>
	<path opacity="0.4" d="M19.1858 0H13.8493C9.46564 0 7.68681 1.74072 7.63599 6.03535H11.5622C16.8987 6.03535 19.3764 8.51303 19.3764 13.8495V17.7757C23.671 17.7249 25.4117 15.946 25.4117 11.5625V6.22594C25.4117 1.77884 23.6329 0 19.1858 0Z" fill="#292D32"/>
	</svg>`;

	replaceColor = '#292D32';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default PasteSvg;
