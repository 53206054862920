import React from 'react';
import './Space.css';

type Props = {
	size: number;
	isAutoResize: boolean;
};

type CustomStyleType = {
	'--size': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Space = (props: Props) => {
	const customStyle: ExtendedDivStyle = {
		'--size': props.isAutoResize ? `${props.size}%` : `${props.size}px`,
	};

	return (
		<div className='space' style={{...customStyle, flexShrink: 0}}></div>
	);
};

Space.displayName = 'Space';

export default Space;
