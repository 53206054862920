const TextFieldInputTypes = {
	mobileoremail: 'mobile-email',
	mobile: 'mobile',
	email: 'email',
	name: 'name',
	purename: 'purename',
	number: 'number',
	floatnumber: 'float-number',
};

export default TextFieldInputTypes;
