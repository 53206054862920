import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './LabelButton.css';

type Props = {
	onClick?: (i?: number) => void;
	label: string;
	index?: number;
	selIndex?: number;
};

export type LabelButtonHandle = Record<string, unknown>;

type CustomStyleType = {
	'--width': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const LabelButton = forwardRef<LabelButtonHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLDivElement>(null);

	useImperativeHandle(ref, () => ({
	}));

	const handleClick = () => {
		if (props.onClick) {
			props.onClick(props.index);
		}
	};

	const btnTitle = props.index === props.selIndex ? 'label-btn-title' : 'label-btn-title-unselected';
	const btnDesgin = props.index === props.selIndex ? 'label-btn-desgin' : 'label-btn-desgin-unselected';

	const updateWidth = () => {
		const textLength = props.label.length;
		const width = (textLength * 7) + 'px';
		return width;
	};

	const customStyle: ExtendedDivStyle = {
		'--width': updateWidth(),
	};

	return (
		<div className='label-button' style={customStyle} onClick={handleClick} ref={btnRef}>
			<label className={btnTitle}>{props.label}</label>
			<div className={btnDesgin}></div>
		</div>

	);
});

LabelButton.displayName = 'LabelButton';

export default LabelButton;
