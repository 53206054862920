import React, {useState, useEffect, type CSSProperties, forwardRef, useImperativeHandle} from 'react';
import './RelToast.css';
import {time} from 'console';

type Props = {
	isTesting?: boolean;
	message?: string;
	isError?: boolean;
	prefix?: string;
};

export type RelToastHandle = {
	trigger: (hasError: boolean, msg: string) => void;
};

const RelToast = forwardRef<RelToastHandle, Props>((props, ref) => {
	const [showRelToast, setShowRelToast] = useState<boolean>(props.isTesting ?? false);
	const [message, setMessage] = useState<string>(props.message ?? '');
	const [isError, setIsError] = useState<boolean>(props.isError ?? false);

	useEffect(() => {
		const handleToastTimeout = () => {
			if (!props.isTesting) {
				let timer: NodeJS.Timeout;
				if (showRelToast) {
					timer = setTimeout(() => {
						setShowRelToast(false);
						setMessage('');
					}, 2000);
				}

				return () => {
					clearTimeout(timer);
				};
			}
		};

		return handleToastTimeout();
	}, [showRelToast, props.isTesting]);

	useImperativeHandle(ref, () => ({
		trigger(hasError: boolean, msg?: string) {
			if (!showRelToast && msg) {
				setShowRelToast(true);
				setIsError(hasError);
				setMessage(msg);
			}
		},
	}));

	const sblClassName = `rel-toast-label ${isError ? 'invalid' : 'valid'}`;

	// Return showRelToast && <label className={sblClassName}>{props.prefix ? `${props.prefix} ${message}` : message }</label>;
	return showRelToast ? (
		<label className={sblClassName}>{props.prefix ? `${props.prefix} ${message}` : message}</label>
	) : null;
});

RelToast.displayName = 'RelToast';

export default RelToast;
