import React, {createContext, useContext, useEffect, useState} from 'react';
import {type UserDetailsModel} from '../models/auth';
import AuthService from '../services/auth';
import {type RestaurantModel} from '../models/restaurants';
import RestaurantsService from '../services/restaurants';
import Router from '../routes/Router';
import RouteNames from '../routes/RouteNames';

export type AuthContextResult = {
	data: UserDetailsModel | undefined;
	restData: RestaurantModel[] | undefined;
	selRest: string;
	selRestRef: number;
	onSelectRest: (i: number) => void;
	onLoad: (b: boolean) => void;
	fetchRest: () => Promise<void>;
	isLoading: boolean;
	isAccessApproved: boolean;
};

const AuthContext = createContext<AuthContextResult>({
	data: undefined, restData: undefined, onSelectRest(i: number) {
		//
	}, selRest: '', selRestRef: 0, isLoading: true, async fetchRest() {
		//
	}, isAccessApproved: false, onLoad() {
		//
	},
});

export function useAuthContext(): AuthContextResult {
	return useContext(AuthContext);
}

export function AuthProvider({children}: {children: JSX.Element}) {
	const [isLoading, setLoading] = useState<boolean>(true);
	const [data, setData] = useState<UserDetailsModel | undefined>();
	const [isAccessApproved, setAccessApproved] = useState<boolean>(false);

	const [selRest, setSelRest] = useState<string>('');
	const [selRestRef, setSelRestRef] = useState<number>(0);
	const [restData, setRestData] = useState<RestaurantModel[] | undefined>();

	useEffect(() => {
		const fetchUserData = async () => {
			await getUserDetails(false);
		};

		void fetchUserData();
	}, []);

	const getUserDetails = async (hasChild: boolean) => {
		if (!isLoading) {
			setLoading(true);
		}

		await new AuthService().userDetails().then(val => {
			if (val.errorMsg === 'Account not approved') {
				setAccessApproved(false);
			} else if (val.errorMsg === 'Unauthorized') {
				localStorage.clear();
				Router.navigateTo!(RouteNames.auth);
			} else if (val.res) {
				setAccessApproved(val.res.data!.hasAccess);
				if (val.res.data!.hasAccess) {
					setData(val.res.data);
				}
			}
		}).finally(() => {
			if (!hasChild) {
				setLoading(false);
			}
		});
	};

	const getRestaurants = async (hasLoad: boolean) => {
		if (!isLoading && hasLoad) {
			setLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.res) {
				setRestData(val.res.data);
				if (val.res.data!.length !== 0) {
					if (selRest.length === 0) {
						setSelRest(val.res.data![0]._id);
					}
				}
			}
		}).finally(() => {
			if (hasLoad) {
				setLoading(false);
			}
		});
	};

	const onSelectRest = (i: number) => {
		if (restData !== undefined) {
			setSelRest(restData[i]._id);
			setSelRestRef(i);
		}
	};

	const fetchRest = async () => {
		await getRestaurants(false);
	};

	const onLoad = (b: boolean) => {
		setLoading(b);
	};

	return (
		<AuthContext.Provider value={{data, restData, onSelectRest, fetchRest, onLoad, selRest, selRestRef, isLoading, isAccessApproved}}>
			{children}
		</AuthContext.Provider>
	);
}

