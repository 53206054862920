import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './TimePicker.css';
import Arrow from '../../assets/icons/arrow';

type Props = {
	onChange: () => void;
	isLoading: boolean;
	initValue?: string;
};

export type TimePickerHandle = {
	pickedTime: () => string;
};

const TimePicker = forwardRef<TimePickerHandle, Props>((props, ref) => {
	const arrowIcon = new Arrow();
	const [hour, setHour] = useState<string>();
	const [minute, setMinute] = useState<string>();
	const [isDragging, setIsDragging] = useState(false);
	const [dragStartY, setDragStartY] = useState(0);

	const hourRef = useRef<HTMLDivElement>(null);
	const minuteRef = useRef<HTMLDivElement>(null);

	useImperativeHandle(ref, () => ({
		pickedTime() {
			return `${hour}:${minute?.toString().length === 1 ? '0' + minute : minute}`;
		},
	}));

	useEffect(() => {
		props.onChange();
	}, [hour, minute]);

	useEffect(() => {
		if (props.initValue) {
			const va = props.initValue.split(':');
			setHour(va[0]);
			hourRef.current!.textContent = va[0];
			setMinute(va[1]);
			minuteRef.current!.textContent = va[1];
		} else {
			setHour('0');
			hourRef.current!.textContent = '--';
			setMinute('0');
			minuteRef.current!.textContent = '--';
		}
	}, [props.initValue]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, isHour: boolean) => {
		if (!props.isLoading) {
			const allowedKeys = ['Backspace', 'Delete'];

			if (
				(e.key >= '0' && e.key <= '9')
				|| allowedKeys.includes(e.key)
			) {
				// Continue with normal behavior
			} else if (e.key === 'ArrowUp') {
				onIncrement(isHour);
			} else if (e.key === 'ArrowDown') {
				onDecrement(isHour);
			} else {
				e.preventDefault();
			}
		}
	};

	const handleHourInput = () => {
		if (hourRef.current && !props.isLoading) {
			const value = hourRef.current.textContent!.replace(/[^0-9]/g, '');

			if ((hour && hour.length === 1 && (Number(hour) >= 3 && Number(hour) <= 9))) {
				// SetConso('R' + value);
				hourRef.current.textContent = value.replace(hour, '');
				setHour(value.replace(hour, ''));
			} else if (!hour || (hour && hour.length === 0)) {
				// SetConso('M' + value);
				hourRef.current.textContent = value;
				setHour(value);
			} else if (hour && hour.length === 1 && Number(hour) === 1) {
				// SetConso('S' + value);
				hourRef.current.textContent = `${hour}${value.replace(hour, '')}`;
				setHour(`${hour}${value.replace(hour, '')}`);
			} else if (hour && hour.length === 1 && Number(hour) === 2 && (Number(value.replace(hour, '')) >= 0 && Number(value.replace(hour, '')) <= 4)) {
				// SetConso('Q' + value);
				hourRef.current.textContent = `${hour}${value.replace(hour, '')}`;
				setHour(`${hour}${value.replace(hour, '')}`);
				if (`${hour}${value.replace(hour, '')}` === '24') {
					minuteRef.current!.textContent = '';
					setMinute('0');
				}
			} else {
				handleHourInputElse();
			}
		}

		cursorPlacement(true);
	};

	const handleHourInputElse = () => {
		if (hourRef.current && !props.isLoading) {
			const value = hourRef.current.textContent!.replace(/[^0-9]/g, '');

			if ((hour && value.length === 3) && value.includes(hour)) {
				// SetConso('G' + value);
				hourRef.current.textContent = value.replace(hour, '');
				setHour(value.replace(hour, ''));
			} else if (hour && hour.length === 1 && hour === '0') {
				// SetConso('K' + value);
				hourRef.current.textContent = value.replaceAll('0', '');
				setHour(value.replaceAll('0', ''));
			} else {
				// SetConso('H' + value);
				hourRef.current.textContent = '';
				setHour('');
			}
		}
	};

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>, isHour: boolean) => {
		if (isDragging && !props.isLoading) {
			const newpos = e.clientY - dragStartY;
			if (newpos < 0) {
				onIncrement(isHour);
			} else {
				onDecrement(isHour);
			}
		}
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>, isHour: boolean) => {
		e.preventDefault();
		cursorPlacement(isHour);

		setIsDragging(true);
		setDragStartY(e.clientY);
	};

	const cursorPlacement = (isHour: boolean) => {
		const contentEditable = isHour ? hourRef.current : minuteRef.current;

		if (contentEditable) {
			contentEditable.focus();

			const selection = window.getSelection();
			const range = document.createRange();
			range.selectNodeContents(contentEditable);
			range.collapse(false);

			if (selection) {
				selection.removeAllRanges();
				selection.addRange(range);
			}
		}
	};

	const onIncrement = (isHour: boolean) => {
		if (isHour && hour && !props.isLoading) {
			if (Number(hour) >= 0 && Number(hour) <= 23) {
				const newVal = (Number(hour) + 1).toString();
				if (hourRef.current) {
					hourRef.current.textContent = newVal;
				}

				setHour(newVal);
			}

			if (hour === '23') {
				if (minuteRef.current) {
					minuteRef.current.textContent = '';
				}

				setMinute('0');
			}
		} else if (minute && !props.isLoading) {
			if (Number(minute) >= 0 && Number(minute) <= 59) {
				const newVal = (Number(minute) + 1).toString();
				if (minuteRef.current) {
					minuteRef.current.textContent = newVal;
				}

				setMinute(newVal);

				if (hour === '24') {
					if (minuteRef.current) {
						minuteRef.current.textContent = '';
					}

					setMinute('0');
				}
			}
		}
	};

	const onDecrement = (isHour: boolean) => {
		if (isHour && hour && !props.isLoading) {
			if (Number(hour) >= 1 && Number(hour) <= 24) {
				const newVal = (Number(hour) - 1).toString();
				if (hourRef.current) {
					hourRef.current.textContent = newVal;
				}

				setHour(newVal);
			}
		} else if (hour !== '24' && minute && !props.isLoading) {
			if (Number(minute) >= 1 && Number(minute) <= 60) {
				const newVal = (Number(minute) - 1).toString();
				if (minuteRef.current) {
					minuteRef.current.textContent = newVal;
				}

				setMinute(newVal);
			}
		} else if (hour === '24') {
			if (minuteRef.current) {
				minuteRef.current.textContent = '';
			}

			setMinute('0');
		}
	};

	const handleMinuteInput = () => {
		if (minuteRef.current && !props.isLoading) {
			const value = minuteRef.current.textContent!.replace(/[^0-9]/g, '');

			const va = hour !== '24' && minute;

			if (va && minute.length === 1 && (Number(minute) >= 7 && Number(minute) <= 9)) {
				minuteRef.current.textContent = value.replace(minute, '');
				setMinute(value.replace(minute, ''));
			} else if (hour !== '24' && (!minute || (minute && minute.length === 0))) {
				minuteRef.current.textContent = value;
				setMinute(value);
			} else if (va && minute.length === 1 && [1, 2, 3, 4, 5].includes(Number(minute))) {
				minuteRef.current.textContent = `${minute}${value.replace(minute, '')}`;
				setMinute(`${minute}${value.replace(minute, '')}`);
			} else if (va && minute.length === 1 && Number(minute) === 6 && Number(value.replace(minute, '')) === 0) {
				minuteRef.current.textContent = `${minute}${value.replace(minute, '')}`;
				setMinute(`${minute}${value.replace(minute, '')}`);
			} else {
				handleMinuteInputElse();
			}
		}

		cursorPlacement(false);
	};

	const handleMinuteInputElse = () => {
		if (minuteRef.current && !props.isLoading) {
			const value = minuteRef.current.textContent!.replace(/[^0-9]/g, '');

			const va = hour !== '24' && minute;

			if (hour !== '24' && (minute && value.length === 3) && value.includes(minute)) {
				minuteRef.current.textContent = value.replace(minute, '');
				setMinute(value.replace(minute, ''));
			} else if (va && minute.length === 1 && minute === '0') {
				minuteRef.current.textContent = value.replaceAll('0', '');
				setMinute(value.replaceAll('0', ''));
			} else {
				minuteRef.current.textContent = '';
				setMinute('');
			}
		}
	};

	return (
		<div className='TimePicker'>
			<div className='TimePicker-wrapper'>
				<div
					ref={hourRef}
					contentEditable={!props.isLoading}
					className='TimePicker-hour'
					onInput={handleHourInput}
					onKeyDown={e => {
						handleKeyDown(e, true);
					}}
					onMouseDown={e => {
						handleMouseDown(e, true);
					}}
					onMouseMove={e => {
						handleMouseMove(e, true);
					}}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseUp}
					spellCheck='false'
				/>
				{/* <label className='TimePicker-label'>{hour === '0' ? '--' : hour}</label> */}
				<div className='TimePicker-controls'>
					<button className='TimePicker-cont-inc' onClick={() => {
						onIncrement(true);
					}}>
						<img className='TimePicker-controls-img' src={arrowIcon.normalUp()} alt='SVG Image' />
					</button>
					<button className='TimePicker-cont-dec' onClick={() => {
						onDecrement(true);
					}}>
						<img className='TimePicker-controls-img' src={arrowIcon.normalDown()} alt='SVG Image' />
					</button>
				</div>
			</div>
			<label className='TimePicker-divider'>:</label>
			<div className='TimePicker-wrapper'>
				<div
					ref={minuteRef}
					contentEditable={!props.isLoading}
					className='TimePicker-minute'
					onInput={handleMinuteInput}
					onKeyDown={e => {
						handleKeyDown(e, false);
					}}
					onMouseDown={e => {
						handleMouseDown(e, false);
					}}
					onMouseMove={e => {
						handleMouseMove(e, false);
					}}
					onMouseUp={handleMouseUp}
					onMouseLeave={handleMouseUp}
					spellCheck='false'
				>
				</div>
				{/* <label className='TimePicker-label'>{minute === '0' ? '--' : minute}</label> */}
				<div className='TimePicker-controls'>
					<button className='TimePicker-cont-inc' onClick={() => {
						onIncrement(false);
					}}>
						<img className='TimePicker-controls-img' src={arrowIcon.normalUp()} alt='SVG Image' />
					</button>
					<button className='TimePicker-cont-dec' onClick={() => {
						onDecrement(false);
					}}>
						<img className='TimePicker-controls-img' src={arrowIcon.normalDown()} alt='SVG Image' />
					</button>
				</div>
			</div>
		</div >
	);
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
