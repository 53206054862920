import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './ListSlots.css';
import Space from '../Space/Space';
import ListView from '../ListView/ListView';
import Button, {type ButtonHandle} from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import Delete from '../../assets/icons/delete';
import TextField, {type TextFieldHandle} from '../TextField/TextField';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';

type Props = {
	color?: string;
	title: string;
	placeHold: string;
	isLoading?: boolean;
};

export type ListSlotsHandle = {
	data: () => string[];
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

type Datas = {
	data: string;
};

const ListSlots = forwardRef<ListSlotsHandle, Props>((props, ref) => {
	const [data, setData] = useState<Datas[]>([]);
	const onAddBtn = useRef<ButtonHandle>(null);
	const inputRef = useRef<TextFieldHandle>(null);
	const mainref = useRef<HTMLDivElement>(null);
	const delIcon = new Delete();

	useImperativeHandle(ref, () => ({
		data() {
			return data.map(v => v.data);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const handleAdd = () => {
		if (props.isLoading !== true) {
			onAddBtn.current?.complete();
			const newVal = inputRef.current!.getValue();
			if (newVal !== '' && !data.map(v => v.data).includes(newVal)) {
				setData([...data, {data: newVal}]);
			}
		}
	};

	const handleDel = (i: number) => {
		if (props.isLoading !== true) {
			onAddBtn.current?.complete();
			if (data[i]) {
				setData(data.filter(v => v !== data[i]));
			}
		}
	};

	return (
		<div className='ListSlots' tabIndex={0} ref={mainref}>
			<div className='ListSlots-cont'>
				<TextField ref={inputRef} isLoading={props.isLoading === true}
					label={props.title} styles={TextFieldStyles.normal} placeholder={props.placeHold}
					inputType={TextFieldInputTypes.name}></TextField>
				<Space size={10} isAutoResize={false}></Space>
				<Button ref={onAddBtn} label={'Add'} onClick={handleAdd}></Button>
			</div>
			<Space size={20} isAutoResize={false}></Space>
			<div className='ListSlots-listview'>
				<ListView data={data} adapter={function (i: number, item: Datas | number): JSX.Element {
					const itemData = item as Datas;
					return <div className='TimeSlots-list-adapter'>
						<label className='TimeSlots-list-adapter-index'>{i + 1}</label>
						<div className='TimeSlots-list-adapter-card'>
							<label className='TimeSlots-list-adapter-title'>{`${itemData.data}`}</label>
							<Space size={10} isAutoResize={true}></Space>
							<IconButton hasSplashBg={true} icon={delIcon.white()} bgColor='rgb(251, 16, 51)' iconSize={10} onClick={() => {
								handleDel(i);
							}}></IconButton>
							<Space size={10} isAutoResize={false}></Space>
						</div>
					</div>;
				}} adapterHeight={35} />
			</div>
		</div>
	);
});

ListSlots.displayName = 'ListSlots';

export default ListSlots;
