import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './Button.css';

type Props = {
	onClick: () => void;
	label: string;
	color?: string;
	isLoading?: boolean;
	inPadding?: string;
};

export type ButtonHandle = {
	complete: () => boolean;
};

type CustomStyleType = {
	'--color': string;
	'--inpad': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Button = forwardRef<ButtonHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLButtonElement>(null);
	// Const [isLoading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		complete() {
			// SetLoading(false);
			// return isLoading;
			return false;
		},
	}));

	const handleClick = () => {
		if (props.isLoading !== true) {
			props.onClick();
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--color': props.color ?? 'rgb(0, 138, 7)',
		'--inpad': props.inPadding ?? '0',
	};

	const child = props.isLoading ? <div className='btn-loading-spinner'></div> : <label className= {props.inPadding ? 'button-label' : 'button-label2'}>{props.label}</label>;

	return (
		<button className={props.inPadding ? 'button-nopad' : 'button'} style={customStyle} onClick={handleClick} ref={btnRef}>
			{child}
		</button>
	);
});

Button.displayName = 'Button';

export default Button;
