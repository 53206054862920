import React, {useRef, useImperativeHandle, forwardRef, useState, type ChangeEvent} from 'react';
import './ImageImport.css';
import Space from '../Space/Space';
import Upload from '../../assets/icons/upload';

type Props = {
	label: string;
	totalImages: number;
	isLoading?: boolean;
};

export type ImageImportHandle = {
	selFiles: () => ImageFile[];
	focus: () => void;
};

type CustomStyleType = {
	'--width': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

type ImageFile = {
	index: number;
	file?: File | undefined;
};

const ImageImport = forwardRef<ImageImportHandle, Props>((props, ref) => {
	const [selectedImage, setSelectedImage] = useState<ImageFile[]>(Array.from({length: props.totalImages}, (i: number) => ({index: i, file: undefined})));
	const fileImpRef = Array.from({length: props.totalImages}, () => useRef<HTMLInputElement>(null));
	const mainref = useRef<HTMLDivElement>(null);
	const upload = new Upload();

	useImperativeHandle(ref, () => ({
		selFiles() {
			return selectedImage.filter(v => v.file !== undefined);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const customStyle: ExtendedDivStyle = {
		'--width': 'updateWidth()',
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (props.isLoading !== true) {
			const file = e.target.files![0];
			const index = e.target.getAttribute('data-index');
			if (file) {
				setSelectedImage(prvImg => prvImg.map((v, j) => j === Number(index) ? {index: Number(index), file} : v));
			}
		}
	};

	return (
		<div className='ImageImport' tabIndex={0} ref={mainref}>
			<label className='ImageImport-label'>{props.label}</label>
			<Space size={10} isAutoResize={false}></Space>
			<div className='ImageImport-wrapper'>
				{selectedImage.map((it, i: number) => <div key={i} className='ImageImport-group' onClick={() => {
					if (props.isLoading !== true) {
						fileImpRef[i].current!.click();
					}
				}}>
					{selectedImage[i].file !== undefined && <img src={URL.createObjectURL(selectedImage[i].file!)} alt='Selected Image' className='ImageImport-img' />}
					<input data-index={i} ref={fileImpRef[i]} className='ImageImport-btn' id='ImageImport-btn' type='file' accept='image/*' onChange={handleFileChange} />
					<img src={upload.normal()} alt='Icon' className='ImageImport-icon' />
				</div>)}
			</div>
		</div>
	);
});

ImageImport.displayName = 'ImageImport';

export default ImageImport;
