import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './OtpField.css';
import {type keyboardKey} from '@testing-library/user-event';

type Props = {
	label: string;
};

export type OtpFieldHandle = {
	validate: () => {
		isValid: boolean;
		msg: string;
	};
	loading: () => void;
	completed: () => void;
	getValue: () => string;
	clearInput: () => void;
};

const OtpField = forwardRef<OtpFieldHandle, Props>((props, ref) => {
	const inputRefs = useRef<HTMLInputElement[]>([]);

	const [isError, setIsError] = useState(true);
	const [isDisabled, setDisabled] = useState(false);
	const [inputValue, setInputValue] = useState<string[]>([]);

	useEffect(() => {
		clearInput();
	}, []);

	const validate = () => {
		const otpValue = getOtpValue();
		if (!otpValue) {
			return {isValid: false, msg: 'Field cannot be empty'};
		}

		if (!/^\d{6}$/.test(otpValue)) {
			return {isValid: false, msg: 'Invalid OTP format'};
		}

		return {isValid: true, msg: 'Success'};
	};

	const getOtpValue = () => inputRefs.current.map(inputRef => inputRef.value).join('');

	useImperativeHandle(ref, () => ({
		validate() {
			const validationResult = validate();
			setIsError(!validationResult.isValid);

			if (!validationResult.isValid) {
				inputRefs.current[0].focus();
			}

			if (validationResult.isValid) {
				inputRefs.current[0].blur();
			}

			return validationResult;
		},
		loading() {
			setDisabled(true);
		},
		completed() {
			setDisabled(false);
		},
		getValue: getOtpValue,
		clearInput,
	}));

	const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
		const {value} = event.target as HTMLInputElement;
		const {keyCode, key} = event as keyboardKey;

		// Remove non-numeric characters
		const numericValue = value.replace(/\D/, '');

		setIsError(!validate().isValid);

		// Move focus backward while delete
		if (key === 'Backspace' && !value && index > 0) {
			inputRefs.current[index - 1].focus();
		} else if (key === 'Delete' && !value && index < inputRefs.current.length - 1) {
			inputRefs.current[index + 1].focus();
		}

		// Remove focus when fields are filled
		if (numericValue && key !== 'Backspace' && key !== 'Delete') {
			inputRefs.current[index].blur();
		}

		if (numericValue) {
			// If input is number then move focus to next
			if (index < inputRefs.current.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		} else if (keyCode === 8 && index > 0) {
			inputRefs.current[index - 1].focus();
		}

		const newInputValue = [...inputValue];
		newInputValue[index] = numericValue;
		setInputValue(newInputValue);

		(event.target as HTMLInputElement).value = numericValue;
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
		event.preventDefault();
		const pastedData = event.clipboardData.getData('text/plain');
		// Remove non-numeric characters
		const sanitizedValue = pastedData.replace(/\D/g, '');
		// Limit to 6 characters
		const otpArray = sanitizedValue.split('').slice(0, 6);

		otpArray.forEach((value: string, index: number) => {
			inputRefs.current[index].value = value;
			if (index < inputRefs.current.length - 1) {
				inputRefs.current[index + 1].focus();
			}
		});
	};

	const clearInput = () => {
		setInputValue([]);
		inputRefs.current.forEach(inputRef => {
			if (inputRef) {
				inputRef.value = '';
			}
		});
	};

	const inputClassName = `${isError ? 'invalid' : 'valid'}`;

	return (
		<div className='otpField-label'>
			{Array.from({length: 6}).map((_, index) => {
				const handleInputRef = (inputRef: HTMLInputElement) => {
					if (inputRef) {
						inputRefs.current[index] = inputRef;
					}
				};

				return (
					<input
						key={index}
						className={`otp-input-field ${inputClassName} index-${index}`}
						value={inputValue[index]}
						onChange={event => {
							handleInputChange(index, event);
						}}
						onKeyDown={event => {
							handleInputChange(index, event);
						}}
						onPaste={event => {
							handlePaste(event);
						}}
						type='text'
						maxLength={1}
						placeholder='0'
						ref={handleInputRef}
						disabled={isDisabled}
						autoComplete='off'
					/>
				);
			})}
		</div>
	);
});

OtpField.displayName = 'OtpField';

export default OtpField;
