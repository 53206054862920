import React, {useRef, useImperativeHandle, forwardRef, useState, EventHandler, MouseEventHandler} from 'react';
import './OutlineButton.css';

type Props = {
	onClick: () => void;
	label: string;
	padTopBottom?: string;
	btnColor?: string;
	isLoading?: boolean;
	width?: string;
};

export type OutlineButtonHandle = Record<string, unknown>;

type CustomStyleType = {
	'--padTopBottom': string;
	'--btnColor': string;
	'--width': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const OutlineButton = forwardRef<OutlineButtonHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLButtonElement>(null);

	useImperativeHandle(ref, () => ({
	}));

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		if (props.isLoading !== true) {
			e.stopPropagation();
			props.onClick();
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--padTopBottom': props.padTopBottom ?? '5px',
		'--btnColor': props.btnColor ?? 'rgb(255, 0, 98)',
		'--width': props.width ?? 'auto',
	};

	return (
		<button className='outline-button' style={customStyle} onClick={handleClick} ref={btnRef}>
			{props.label}
		</button>
	);
});

OutlineButton.displayName = 'OutlineButton';

export default OutlineButton;
