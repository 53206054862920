import React, {useEffect, useRef, useState} from 'react';
import './Loading.css';
import Space from '../../components/Space/Space';

const Loading = () => (
	<div className='Loading'>
		<div className='cupertino-loading-container'>
			<div className='cupertino-spinner'></div>
			<Space size={15} isAutoResize={false}></Space>
			<label className='loading-title'>Please Wait....</label>
		</div>
	</div >
);

export default Loading;
