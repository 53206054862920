class Lock {
	svgRaw = `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9999 12.65C17.8419 12.65 17.0499 14.388 17.0499 17.6V18.964H26.9499V17.6C26.9499 14.388 26.1579 12.65 21.9999 12.65Z" fill="#CD0000"/>
    <path d="M21.9967 28.8165C23.3334 28.8165 24.4167 27.7332 24.4167 26.3965C24.4167 25.06 23.3334 23.9765 21.9967 23.9765C20.6602 23.9765 19.5767 25.06 19.5767 26.3965C19.5767 27.7332 20.6602 28.8165 21.9967 28.8165Z" fill="#CD0000"/>
    <path d="M22 0C9.856 0 0 9.856 0 22C0 34.144 9.856 44 22 44C34.144 44 44 34.144 44 22C44 9.856 34.144 0 22 0ZM33.836 27.5C33.836 32.34 32.34 33.836 27.5 33.836H16.5C11.66 33.836 10.164 32.34 10.164 27.5V25.3C10.164 21.538 11.066 19.8 13.75 19.206V17.6C13.75 15.554 13.75 9.35 22 9.35C30.25 9.35 30.25 15.554 30.25 17.6V19.206C32.934 19.8 33.836 21.538 33.836 25.3V27.5Z" fill="#CD0000"/>
    </svg>`;

	replaceColor = '#CD0000';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default Lock;
