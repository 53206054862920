import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './MenuButton.css';

type Props = {
	onClick?: (i: number) => void;
	icon: string;
	title: string;
	index: number;
	selIndex: number;
};

type Handle = Record<string, unknown>;

const MenuButton = forwardRef<Handle, Props>((props, ref) => {
	const btnRef = useRef(null);

	useImperativeHandle(ref, () => ({
	}));

	const handleClick = () => {
		if (props.onClick) {
			props.onClick(props.index);
		}
	};

	const menuClass = props.index === props.selIndex ? 'menu-button-selected' : 'menu-button-unselected';
	const menuIconClass = props.index === props.selIndex ? 'menu-icon-btn-img-selected' : 'menu-icon-btn-img-unselected';
	const menuTitleClass = props.index === props.selIndex ? 'menu-btn-title-selected' : 'menu-btn-title-unselected';

	return (
		<button className={menuClass} onClick={handleClick} ref={btnRef}>
			<img className={menuIconClass} src={props.icon} alt='icon'></img>
			<label className={menuTitleClass}>{props.title}</label>
		</button>
	);
});

MenuButton.displayName = 'MenuButton';

export default MenuButton;
