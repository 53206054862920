import React, {useEffect, useRef, useState} from 'react';
import './Restaurants.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import ListView from '../../components/ListView/ListView';
import Space from '../../components/Space/Space';
import RestaurantsService from '../../services/restaurants';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type RestaurantModel} from '../../models/restaurants';
import DotStatus from '../../components/DotStatus/DotStatus';
import HorButtons, {type HorButtonsHandle} from '../../components/HorButtons/HorButtons';
import CheckBox from '../../components/CheckBox/CheckBox';
import resttypes from '../../config/resttypes';
import DayTimePicker, {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import TimeSlots, {type TimeSlotsHandle} from '../../components/TimeSlots/TimeSlots';

type RestTypes = {
	_id: string;
	name: string;
};

type DotIndicator = {
	delIsActive: boolean;
	takIsActive: boolean;
	dinIsActive: boolean;
	dirIsActive: boolean;
};

const Restaurants = () => {
	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [restsData, setRestsData] = useState<RestaurantModel[]>([]);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selRsType, setSelRsType] = useState<number[]>([]);
	const [dotIndicator, setDotIndicator] = useState<DotIndicator[]>([]);
	const [selSitings, setSelSitings] = useState<string[]>([]);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const restTypesRef = useRef<HorButtonsHandle>(null);
	const availability = useRef<DayTimePickerHandle>(null);
	const timeSlots = useRef<TimeSlotsHandle>(null);

	useEffect(() => {
		const fetchRestsData = async () => {
			await fetchRests();
		};

		void fetchRestsData();
	}, []);

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		if (i === 1) {
			setSelRest(-1);
			void fetchRests();
			if (timeSlots.current) {
				timeSlots.current.clear();
			}
		}

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	useEffect(() => {
		if (timeSlots.current && timeSlots.current.times.length === 0) {
			timeSlots.current.setTimes(restsData[selRest].dining.timeSlots);
		}
	}, [timeSlots.current]);

	async function fetchRests() {
		if (!isLoading) {
			setLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setRestsData(val.res!.data!);
				setDotIndicator(Array.from({length: val.res!.data!.length}, () => ({
					delIsActive: false,
					takIsActive: false,
					dinIsActive: false,
					dirIsActive: false,
				})));
				dotStatus(true, val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	function onLoadSitings(sr: number, rt: RestaurantModel[]) {
		if (sr !== -1) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			setSelSitings((rt[sr].sitingIds ?? []) as string[]);
		}
	}

	function isOpen(s: string, d: RestaurantModel) {
		switch (s.toLowerCase()) {
			case resttypes.delivery:
				return d.delivery.isOpen;
			case resttypes.takeaway:
				return d.takeaway.isOpen;
			case resttypes.dining:
				return d.dining.isOpen;
			default:
				return false;
		}
	}

	const getAvailInit = (): Array<{
		day: string;
		st: number;
		et: number;
	}> | undefined => {
		if (selRsType.length === 1) {
			const tyNa = restsData[selRest].restTypes[selRsType[0]].name.toLowerCase();

			switch (tyNa) {
				case resttypes.delivery:
					return restsData[selRest].delivery.availability;
				case resttypes.takeaway:
					return restsData[selRest].takeaway.availability;
				case resttypes.dining:
					return restsData[selRest].dining.availability;
				default:
					return undefined;
			}
		}
	};

	useEffect(() => {
		// Start timer only if restaurants tab open
		if (selMenuIndex === 1) {
			const dotStatusInterval = () => {
				dotStatus(false, []);
			};

			// 10 seconds
			const intervalId = setInterval(dotStatusInterval, 10000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [dotIndicator, selMenuIndex]);

	const available = (mo: string, dd: RestaurantModel): boolean => {
		const now = new Date();
		const day = now.toLocaleDateString(undefined, {weekday: 'short'}).toLowerCase();

		const deltypes = dd.delivery;
		const delAvailData = deltypes.availability.find(v => v.day === day);

		const taktypes = dd.takeaway;
		const takAvailData = taktypes.availability.find(v => v.day === day);

		const dintypes = dd.dining;
		const dinAvailData = dintypes.availability.find(v => v.day === day);

		switch (mo) {
			case resttypes.delivery:
				return (!delAvailData || (delAvailData && !(delAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && delAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.takeaway:
				return (!takAvailData || (takAvailData && !(takAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && takAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.dining:
				return (!dinAvailData || (dinAvailData && !(dinAvailData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && dinAvailData.et > parseFloat(`${now.getHours()}.${now.getMinutes()}`))));
			case resttypes.direct:
				return true;
			default:
				return false;
		}
	};

	const isholiday = (mo: string, dd: RestaurantModel): boolean => {
		const now = new Date();
		const month = now.getMonth().toString().length === 1 ? `0${now.getMonth() + 1}` : (now.getMonth() + 1).toString();
		const date = now.getDate().toString().length === 1 ? `0${now.getDate()}` : now.getDate().toString();

		const deltypes = dd.delivery;
		const delHoliData = deltypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		const taktypes = dd.takeaway;
		const takHoliData = taktypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		const dintypes = dd.dining;
		const dinHoliData = dintypes.holiday.find(v => v.date === `${now.getFullYear()}:${month}:${date}`);

		switch (mo) {
			case resttypes.delivery:
				return (delHoliData !== undefined && (delHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (delHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.takeaway:
				return (takHoliData !== undefined && (takHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (takHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.dining:
				return (dinHoliData !== undefined && (dinHoliData.st <= parseFloat(`${now.getHours()}.${now.getMinutes()}`) && (dinHoliData.et - 0.01) >= parseFloat(`${now.getHours()}.${now.getMinutes()}`)));
			case resttypes.direct:
				return true;
			default:
				return false;
		}
	};

	const dotStatus = (isInit: boolean, restInitData: RestaurantModel[]) => {
		const rests = isInit ? restInitData : restsData;
		for (const dd of rests) {
			const data = {
				delIsActive: false,
				takIsActive: false,
				dinIsActive: false,
				dirIsActive: true,
			};

			if ((!dd.delivery.isOpen) || available(resttypes.delivery, dd) || isholiday(resttypes.delivery, dd)) {
				data.delIsActive = false;
			} else {
				data.delIsActive = true;
			}

			if ((!dd.takeaway.isOpen) || available(resttypes.takeaway, dd) || isholiday(resttypes.takeaway, dd)) {
				data.takIsActive = false;
			} else {
				data.takIsActive = true;
			}

			if ((!dd.dining.isOpen) || available(resttypes.dining, dd) || isholiday(resttypes.dining, dd)) {
				data.dinIsActive = false;
			} else {
				data.dinIsActive = true;
			}

			setDotIndicator(prvVal => prvVal.map((vl, j) => (rests.length !== 0 && j === rests.indexOf(dd)) ? data : vl));
		}
	};

	const selService = (i: number) => {
		if (!isLoading) {
			if (selRsType.includes(i)) {
				setSelRsType(selRsType.filter(v => v !== i));
			} else {
				setSelRsType([...selRsType, i]);
			}
		}
	};

	const mainPage = () => <div className='Restaurants-main'>
		<div className='rest-main-left-container'>
			{isLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index-load'>00</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
					</div>
				</div>;
			}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={restsData} adapter={function (i, item): JSX.Element {
				const itemData = item as RestaurantModel;
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
						<Space size={10} isAutoResize={true}></Space>
						<div className='rest-main-container-row'>
							<DotStatus isActive={dotIndicator[i].delIsActive} size={'4px'}></DotStatus>
							<Space size={5} isAutoResize={false}></Space>
							<label className='rest-main-listitem-adapter-dottitle'>Del</label>
							<Space size={15} isAutoResize={false}></Space>
							<DotStatus isActive={dotIndicator[i].takIsActive} size={'4px'}></DotStatus>
							<Space size={5} isAutoResize={false}></Space>
							<label className='rest-main-listitem-adapter-dottitle'>Tak</label>
							<Space size={15} isAutoResize={false}></Space>
							<DotStatus isActive={dotIndicator[i].dinIsActive} size={'4px'}></DotStatus>
							<Space size={5} isAutoResize={false}></Space>
							<label className='rest-main-listitem-adapter-dottitle'>Din</label>
							<Space size={15} isAutoResize={false}></Space>
							<DotStatus isActive={dotIndicator[i].dirIsActive} size={'4px'}></DotStatus>
							<Space size={5} isAutoResize={false}></Space>
							<label className='rest-main-listitem-adapter-dottitle'>Dir</label>
						</div>
					</div>
				</div>;
			}} onSelectItem={i => {
				setSelRsType([]);
				setSelRest(i);
				onLoadSitings(i, restsData);
				if (timeSlots.current) {
					timeSlots.current.setTimes(restsData[i].dining.timeSlots);
				}

				if (availability.current) {
					availability.current.setValue([]);
				}
			}} selItemIndex={selRest} adapterHeight={80} />)}
		</div>
		<div className='rest-main-right-container'>
			{(restsData && restsData.length !== 0 && selRest !== -1) ? (<div className='rest-main-right-container-cont'>
				<label className='rest-main-listitem-adapter-title'>{restsData[selRest].name}</label>
				<Space size={20} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-subtitle'>{restsData[selRest].address}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Cities : ${restsData[selRest].cities.map(v => v.name).toString().replaceAll(',', ',  ')}`}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Contacts : ${restsData[selRest].contacts.map(v => v.isLandline ? v.contactNumber : v.mobileNumber).toString().replaceAll(',', ',  ')}`}</label>
				<Space size={20} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Services : ${restsData[selRest].restTypes.map(v => v.name).toString().replaceAll(',', ',  ')}`}</label>
				<Space size={40} isAutoResize={false}></Space>
				<div style={{visibility: restsData[selRest].dining.timeSlots.length === 0 ? 'hidden' : 'visible', height: restsData[selRest].dining.timeSlots.length === 0 ? 0 : 'auto'}}>
					<Space size={20} isAutoResize={false}></Space>
					<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
						<label className='addrest-label'>Time Slots (24hr format) for dining only</label>
					</div>
					<Space size={5} isAutoResize={false}></Space>
					<div className='addrest-container' style={{height: '200px'}}>
						<TimeSlots ref={timeSlots} isLoading={isLoading}></TimeSlots>
					</div>
				</div>
				<Space size={20} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Services</label>
				<Space size={10} isAutoResize={false}></Space>
				<div className='horlistview-wrapper'>
					<HorButtons ref={restTypesRef} data={restsData[selRest].restTypes.filter(v => v.name.toLowerCase() !== resttypes.direct)} adapter={function (i: number, item: RestTypes | number): JSX.Element {
						const itemData = item as RestTypes;
						const opened = isOpen(itemData.name, restsData[selRest]);
						return <div className='listview-item-adapter'>
							<div className='listview-item-adapter-card'>
								<label className='horlistview-item-adapter-title'>{`${itemData.name}${opened ? ' (Open)' : ' (Closed)'}`}</label>
								<Space size={5} isAutoResize={true}></Space>
								<CheckBox title='' initVal={selRsType.includes(i)} ovrVal={selRsType.includes(i)} onClick={(b: boolean) => {
									selService(i);
								}}></CheckBox>
							</div>
						</div>;
					}} adapterHeight={35} selItemIndex={selRsType} onSelectItem={(i: number) => {
						selService(i);
					}} />
				</div>
				<Space size={30} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Service Availability (Select all service will apply one availability to all)</label>
				<Space size={10} isAutoResize={false}></Space>
				<div className='rest-main-container-row'>
					<DayTimePicker ref={availability} init={getAvailInit()} isLoading={isLoading}></DayTimePicker>
				</div>
				<Space size={30} isAutoResize={false}></Space>
			</div>) : (<div className='rest-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
				<label className='rest-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the details</label>
			</div>)}
		</div>
	</div>;

	return (
		<div className='Restaurants'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='rest-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'Restaurants'}></LabelButton>
			</div>
			<div className='rest-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return mainPage();
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Restaurants;
