import unauthorized from './unauthorized';

export async function serviceError(msg: string): Promise<void> {
	unauthorized(msg);
}

export async function serviceSuccess(): Promise<void> {
	//
}

