import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import './ListView.css';

type ListViewProps<M extends Record<string, any>> = {
	data?: M[];
	dummy?: number;
	adapter: (i: number, item: M | number) => JSX.Element;
	selItemIndex?: number | number[];
	onSelectItem?: (i: number) => void;
	adapterHeight?: number;
	isHorizontal?: boolean;
	isSelectable?: boolean;
	hasDivider?: boolean;
};

export type ListViewHandle = {
	focus: () => void;
};

type CustomStyleType = {
	'--bg': string;
	'--bottomborder': string;
	'--adapterheight': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const ListView = forwardRef<ListViewHandle, ListViewProps<any>>((props, ref) => {
	const scrollRef = useRef<HTMLUListElement>(null);
	const [dummy, setDummy] = useState<number[]>([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrollPosByItem, setScrollPosByItem] = useState(0);

	useEffect(() => {
		const dummy = () => {
			if (props.dummy) {
				const dataFur: number[] = [];
				for (let i = 0; i < props.dummy; i++) {
					dataFur.push(i);
				}

				if (dummy.length === 0) {
					setDummy(dataFur);
				}
			}
		};

		dummy();
	}, []);

	useImperativeHandle(ref, () => ({
		focus() {
			scrollRef.current!.focus();
		},
	}));

	const handleScroll = () => {
		if (scrollRef.current) {
			if (scrollRef.current.scrollTop === 0) {
				setScrollPosition(scrollRef.current.scrollLeft);
			}

			if (scrollRef.current.scrollLeft === 0) {
				setScrollPosition(scrollRef.current.scrollTop);
			}
		}
	};

	useEffect(() => {
		const listen = () => {
			if (scrollRef.current) {
				scrollRef.current.addEventListener('scroll', handleScroll);
			}

			return () => {
				if (scrollRef.current) {
					scrollRef.current.removeEventListener('scroll', handleScroll);
				}
			};
		};

		listen();
	}, [scrollRef.current]);

	useEffect(() => {
		const scroll = () => {
			if (scrollRef.current && scrollPosByItem !== 0) {
				scrollRef.current.scrollBy({top: scrollPosByItem});
			}
		};

		scroll();
	}, [scrollPosByItem]);

	const onClickItem = (i: number) => {
		setScrollPosByItem(scrollPosition);
		if (props.onSelectItem) {
			props.onSelectItem(i);
		}
	};

	const dataFinal = props.dummy ? dummy : props.data!;
	const changeListStyle = props.isHorizontal ? 'listview-list-hor' : 'listview-list';
	const chnageAdpStyle = props.isHorizontal ? 'listview-adapter-item-hor' : 'listview-adapter-item';
	const chnageAdpStyle2 = props.isHorizontal ? 'listview-adapter-item-nohover-hor' : 'listview-adapter-item-nohover';

	return (
		<ul className={changeListStyle} tabIndex={0} ref={scrollRef}>
			{dataFinal.map((item, i) => {
				const customStyle: ExtendedDivStyle = {
					'--bg': (Array.isArray(props.selItemIndex) && props.selItemIndex.includes(i)) || props.selItemIndex === i ? 'rgba(235, 235, 235, 0.436)' : 'none',
					'--bottomborder': props.hasDivider === false ? 'none' : (dataFinal.length === (i + 1) ? 'none' : '1px solid rgb(214, 223, 231)'),
					'--adapterheight': (props.adapterHeight ? `${props.adapterHeight}px` : '60px'),
				};
				return <li className={(Array.isArray(props.selItemIndex) && props.selItemIndex.includes(i)) || props.selItemIndex === i || props.isSelectable === false ? chnageAdpStyle2 : chnageAdpStyle} style={customStyle} key={i} onClick={() => {
					onClickItem(i);
				}}>{props.adapter(i, item)}</li>;
			})}
		</ul>
	);
});

ListView.displayName = 'ListView';

export default ListView;
