class FoodsSvg {
	svgRaw = `<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0735 0H6.35783C2.54313 0 0 1.90735 0 6.35783V15.2588C0 19.7093 2.54313 21.6166 6.35783 21.6166H19.0735C22.8882 21.6166 25.4313 19.7093 25.4313 15.2588V6.35783C25.4313 1.90735 22.8882 0 19.0735 0ZM6.03994 15.8946C6.03994 16.4159 5.60761 16.8483 5.08626 16.8483C4.56492 16.8483 4.13259 16.4159 4.13259 15.8946V5.72205C4.13259 5.20071 4.56492 4.76837 5.08626 4.76837C5.60761 4.76837 6.03994 5.20071 6.03994 5.72205V15.8946ZM9.85464 15.8946C9.85464 16.4159 9.42231 16.8483 8.90096 16.8483C8.37962 16.8483 7.94729 16.4159 7.94729 15.8946V14.623C7.94729 14.1017 8.37962 13.6693 8.90096 13.6693C9.42231 13.6693 9.85464 14.1017 9.85464 14.623V15.8946ZM9.85464 10.8083C9.85464 11.3297 9.42231 11.762 8.90096 11.762C8.37962 11.762 7.94729 11.3297 7.94729 10.8083V5.72205C7.94729 5.20071 8.37962 4.76837 8.90096 4.76837C9.42231 4.76837 9.85464 5.20071 9.85464 5.72205V10.8083ZM13.6693 15.8946C13.6693 16.4159 13.237 16.8483 12.7157 16.8483C12.1943 16.8483 11.762 16.4159 11.762 15.8946V5.72205C11.762 5.20071 12.1943 4.76837 12.7157 4.76837C13.237 4.76837 13.6693 5.20071 13.6693 5.72205V15.8946ZM17.484 15.8946C17.484 16.4159 17.0517 16.8483 16.5304 16.8483C16.009 16.8483 15.5767 16.4159 15.5767 15.8946V10.8083C15.5767 10.287 16.009 9.85464 16.5304 9.85464C17.0517 9.85464 17.484 10.287 17.484 10.8083V15.8946ZM17.484 6.99361C17.484 7.51496 17.0517 7.94729 16.5304 7.94729C16.009 7.94729 15.5767 7.51496 15.5767 6.99361V5.72205C15.5767 5.20071 16.009 4.76837 16.5304 4.76837C17.0517 4.76837 17.484 5.20071 17.484 5.72205V6.99361ZM21.2987 15.8946C21.2987 16.4159 20.8664 16.8483 20.3451 16.8483C19.8237 16.8483 19.3914 16.4159 19.3914 15.8946V5.72205C19.3914 5.20071 19.8237 4.76837 20.3451 4.76837C20.8664 4.76837 21.2987 5.20071 21.2987 5.72205V15.8946Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default FoodsSvg;
