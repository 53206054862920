import React, {useRef, useState} from 'react';
import {type ButtonHandle} from '../../components/Button/Button';
import Router from '../../routes/Router';
import RouteNames from '../../routes/RouteNames';
import './Dashboard.css';
import IconButton from '../../components/IconButton/IconButton';
import CircleAvatar from '../../components/CircleAvatar/CircleAvatar';
import Space from '../../components/Space/Space';
import logout from '../../assets/logout.svg';
import toiLogo from '../../assets/toiLogo.png';
import RestaurantSvg from '../../assets/icons/restaurants';
import FoodsSvg from '../../assets/icons/foods';
import MenuButton from '../../components/MenuButton/MenuButton';
import Restaurants from '../Restaurants/Restaurants';
import Loading from '../Loading/Loading';
import AccessDenied from '../AccessDenied/AccessDenied';
import {useAuthContext} from '../../context/authContext';
import Foods from '../Foods/Foods';
import AuthService from '../../services/auth';

const Dashboard = () => {
	const authRes = useAuthContext();

	const SpaceBetweenMenuItems = 5;
	const TopMenuIconSize = 15;

	// Assets
	const restaurants = new RestaurantSvg();
	const foods = new FoodsSvg();

	const [selMenuIndex, setMenuIndex] = useState<number>(1);

	const onLogoutBtn = useRef<ButtonHandle>(null);

	const onLogout = async () => {
		authRes.onLoad(true);
		await new AuthService().logout().then(val => {
			authRes.onLoad(false);
			if (!val.hasError && val.res) {
				localStorage.clear();
				Router.navigateTo!(RouteNames.auth);
			}
		});
	};

	const handleOnMenuClick = (i?: number) => {
		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const ownerPage = () => <div className='Dashboard'>
		<div className='dash-left-panel'>
			<Space size={15} isAutoResize={true}></Space>
			<MenuButton title='Restaurants' index={1} selIndex={selMenuIndex} icon={restaurants.white()} onClick={handleOnMenuClick} ></MenuButton>
			<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
			<MenuButton title='Foods' index={2} selIndex={selMenuIndex} icon={foods.white()} onClick={handleOnMenuClick} ></MenuButton>
		</div>
		<div className='dash-right-panel'>
			<div className='dash-top-panel'>
				<div className='dash-top-panel-left'>
					<IconButton hasSplashBg={true} index={1} selIndex={selMenuIndex} icon={restaurants.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick}></IconButton>
					<Space size={SpaceBetweenMenuItems} isAutoResize={false}></Space>
					<IconButton hasSplashBg={true} index={2} selIndex={selMenuIndex} icon={foods.white()} iconSize={TopMenuIconSize} onClick={handleOnMenuClick} ></IconButton>
				</div>
				<div className='dash-top-panel-right'>
					<div className='dash-top-namecard'>
						<label className='namecard-head'>{`${authRes.data!.firstName} ${authRes.data!.lastName}`}</label>
						<Space size={3} isAutoResize={false}></Space>
						<div className='namecard-sub'>
							<IconButton ref={onLogoutBtn} icon={logout} onClick={onLogout}></IconButton>
							<Space size={5} isAutoResize={false}></Space>
							<label className='namecard-sub-role'>Data Entry Assistant</label>
						</div>
					</div>
					<Space size={5} isAutoResize={false}></Space>
					<CircleAvatar img={toiLogo}></CircleAvatar>
					<Space size={10} isAutoResize={false}></Space>
				</div>
			</div>
			<div className='dash-right-content'>
				{(() => {
					switch (selMenuIndex) {
						case 1:
							return <Restaurants />;
						case 2:
							return <Foods />;
						default:
							return <div />;
					}
				})()}
			</div>
		</div>
	</div >;

	return (
		authRes.isLoading
			? <Loading />
			: authRes.isAccessApproved ? ownerPage() : <AccessDenied />
	);
};

export default Dashboard;
