import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './HorButtons.css';

type HorButtonsProps<M extends Record<string, any>> = {
	data?: M[];
	dummy?: number;
	adapter: (i: number, item: M | number) => JSX.Element;
	selItemIndex?: number | number[];
	onSelectItem?: (i: number) => void;
	adapterHeight?: number;
	adapterWidth?: number;
};

export type HorButtonsHandle = {
	focus: () => void;
};

type CustomStyleType = {
	'--bg': string;
	'--adapterheight': string;
	'--adapterwidth': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const HorButtons = forwardRef<HorButtonsHandle, HorButtonsProps<any>>((props, ref) => {
	const scrollRef = useRef<HTMLUListElement>(null);
	const [dummy, setDummy] = useState<number[]>([]);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrollPosByItem, setScrollPosByItem] = useState(0);

	useEffect(() => {
		const dummy = () => {
			if (props.dummy) {
				const dataFur: number[] = [];
				for (let i = 0; i < props.dummy; i++) {
					dataFur.push(i);
				}

				if (dummy.length === 0) {
					setDummy(dataFur);
				}
			}
		};

		dummy();
	}, []);

	const handleScroll = () => {
		if (scrollRef.current) {
			if (scrollRef.current.scrollTop === 0) {
				setScrollPosition(scrollRef.current.scrollLeft);
			}

			if (scrollRef.current.scrollLeft === 0) {
				setScrollPosition(scrollRef.current.scrollTop);
			}
		}
	};

	useImperativeHandle(ref, () => ({
		focus() {
			scrollRef.current!.focus();
		},
	}));

	// UseEffect(() => {
	// 	const listen = () => {
	// 		if (scrollRef.current) {
	// 			scrollRef.current.addEventListener('scroll', handleScroll);
	// 		}
	// 	};

	// 	listen();

	// 	return () => {
	// 		if (scrollRef.current) {
	// 			scrollRef.current.removeEventListener('scroll', handleScroll);
	// 		}
	// 	};
	// }, []);

	useEffect(() => {
		const listen = () => {
			if (scrollRef.current) {
				scrollRef.current.addEventListener('scroll', handleScroll);
			}

			return () => {
				if (scrollRef.current) {
					scrollRef.current.removeEventListener('scroll', handleScroll);
				}
			};
		};

		listen();
	}, []);

	// UseEffect(() => {
	// 	const scroll = () => {
	// 		if (scrollRef.current && scrollPosByItem !== 0) {
	// 			scrollRef.current?.scrollBy({top: scrollPosByItem});
	// 		}
	// 	};

	// 	scroll();
	// }, []);

	useEffect(() => {
		const scroll = () => {
			if (scrollRef.current && scrollPosByItem !== 0) {
				scrollRef.current.scrollBy({top: scrollPosByItem});
			}
		};

		scroll();
	}, [scrollPosByItem]);

	const onClickItem = (i: number) => {
		setScrollPosByItem(scrollPosition);
		if (props.onSelectItem) {
			props.onSelectItem(i);
		}
	};

	const dataFinal = props.dummy ? dummy : props.data!;

	return (
		<ul className='HorButtons-list' tabIndex={0} ref={scrollRef}>
			{dataFinal.map((item, i) => {
				const customStyle: ExtendedDivStyle = {
					'--bg': (Array.isArray(props.selItemIndex) && props.selItemIndex.includes(i)) || props.selItemIndex === i ? 'rgba(235, 235, 235, 0.436)' : 'none',
					'--adapterheight': props.adapterHeight ? `${props.adapterHeight}px` : '60px',
					'--adapterwidth': props.adapterWidth ? `${props.adapterWidth}px` : '100%',
				};
				return <li className={(Array.isArray(props.selItemIndex) && props.selItemIndex.includes(i)) || props.selItemIndex === i ? 'HorButtons-adapter-item-nohover' : 'HorButtons-adapter-item'} style={customStyle} key={i} onClick={() => {
					onClickItem(i);
				}}>{props.adapter(i, item)}</li>;
			})}
		</ul>
	);
});

HorButtons.displayName = 'HorButtons';

export default HorButtons;
