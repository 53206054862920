import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './CircleAvatar.css';

type Props = {
	onClick?: () => void;
	img: string;
};

export type CircleAvatarHandle = {
	complete: () => boolean;
};

const CircleAvatar = forwardRef<CircleAvatarHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLButtonElement>(null);
	const [isLoading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		complete() {
			setLoading(false);
			return isLoading;
		},
	}));

	return (
		<div className='cir-avatar'>
			<img src={props.img} alt='icon' />
		</div>
	);
});

CircleAvatar.displayName = 'CircleAvatar';

export default CircleAvatar;
