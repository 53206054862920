import React, {useRef, useImperativeHandle, forwardRef, useState, type ChangeEvent} from 'react';
import './TextField.css';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import type TextFieldOptions from '../../utils/textfield/options';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import Space from '../Space/Space';

type Props = {
	inputType: string;
	label: string;
	options?: TextFieldOptions;
	styles?: string;
	placeholder?: string;
	onChanged?: (s: string) => void;
	isLoading: boolean;
};

export type TextFieldHandle = {
	validate: () => {
		isValid: boolean;
		msg: string;
	};
	getValue: () => string;
	clearInput: () => void;
	initValue: (s: string) => void;
};

const TextField = forwardRef<TextFieldHandle, Props>((props, ref) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isError, setIsError] = useState(true);
	// Const [isDisabled, setDisabled] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const validate = () => {
		const inputVal = inputRef.current!.value.trim();

		if (!inputVal) {
			return {isValid: false, msg: 'Field cannot be empty'};
		}

		switch (props.inputType) {
			case TextFieldInputTypes.mobileoremail:
				return mobileoremail(inputVal);
			case TextFieldInputTypes.name:
				return name(inputVal);
			case TextFieldInputTypes.purename:
				return purename(inputVal);
			case TextFieldInputTypes.number:
				return number(inputVal);
			case TextFieldInputTypes.mobile:
				return mobile(inputVal);
			case TextFieldInputTypes.floatnumber:
				return floatnumber(inputVal);
			default:
				return {isValid: false, msg: 'failed to validate'};
		}
	};

	function mobileoremail(inputVal: string) {
		const isEmail = inputVal.includes('@');
		const isMobile = inputVal.includes('+');

		if ((isEmail && isMobile) || (!isEmail && !isMobile)) {
			return {isValid: false, msg: 'Enter valid email or mobile number'};
		}

		if (isEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputVal)) {
			return {isValid: false, msg: 'Invalid email format'};
		}

		if (isMobile && (!/^[+]?\d+$/.test(inputVal) || inputVal.length < 9)) {
			return {isValid: false, msg: 'Invalid mobile number format'};
		}

		return {isValid: true, msg: 'Success'};
	}

	function mobile(inputVal: string) {
		const isMobile = inputVal.includes('+');

		if (!isMobile) {
			return {isValid: false, msg: 'Enter valid mobile number'};
		}

		if (isMobile && (!/^[+]?\d+$/.test(inputVal) || inputVal.length < 9)) {
			return {isValid: false, msg: 'Invalid mobile number format'};
		}

		return {isValid: true, msg: 'Success'};
	}

	function name(inputVal: string) {
		if (props.options) {
			if (props.options.maxLength && inputVal.length < props.options.maxLength) {
				return {isValid: false, msg: 'Invalid number format'};
			}

			if (props.options.minLength && inputVal.length < props.options.minLength) {
				return {isValid: false, msg: 'Invalid number format'};
			}
		}

		return {isValid: true, msg: 'Success'};
	}

	function noorname(inputVal: string) {
		if (inputVal.length < 1) {
			return {isValid: false, msg: 'Invalid number format'};
		}

		return {isValid: true, msg: 'Success'};
	}

	function purename(inputVal: string) {
		if (inputVal.length < 3) {
			return {isValid: false, msg: 'Invalid mobile number format'};
		}

		if (/\d/.test(inputVal)) {
			return {isValid: false, msg: 'Input should not contain numbers'};
		}

		return {isValid: true, msg: 'Success'};
	}

	function number(inputVal: string) {
		if (!/^\d+$/.test(inputVal)) {
			return {isValid: false, msg: 'Invalid number format'};
		}

		if (props.options) {
			if (inputVal.length > props.options.maxLength!) {
				return {isValid: false, msg: 'Invalid number format'};
			}
		}

		return {isValid: true, msg: 'Success'};
	}

	function floatnumber(inputVal: string) {
		if (/[^0-9.]/g.test(inputVal) || inputVal.split('.').length > 2
			|| (inputVal.includes('.') && inputVal.length === 1)
			|| (inputVal.includes('.') && inputVal.split('.')[1].length === 0)) {
			return {isValid: false, msg: 'Invalid number format'};
		}

		if (props.options) {
			if (inputVal.length > props.options.maxLength!) {
				return {isValid: false, msg: 'Invalid number format'};
			}
		}

		return {isValid: true, msg: 'Success'};
	}

	useImperativeHandle(ref, () => ({
		validate() {
			const validationResult = validate();
			setIsError(!validationResult.isValid);

			if (!validationResult.isValid) {
				inputRef.current!.focus();
			}

			if (validationResult.isValid) {
				inputRef.current!.blur();
			}

			return validationResult;
		},
		getValue,
		clearInput,

		initValue(s: string) {
			setInputValue(s);
		},
	}));

	const handleInputChange = (val: ChangeEvent<HTMLInputElement>) => {
		setInputValue(val.currentTarget.value);
		setIsError(!validate().isValid);
		if (props.onChanged) {
			props.onChanged(val.currentTarget.value);
		}
	};

	const getValue = () => inputRef.current!.value;

	const clearInput = () => {
		setInputValue('');
	};

	const inputClassName = `input-field ${isError ? 'invalid' : 'valid'}`;

	const inputElement = (ph: string): JSX.Element => <input
		className={inputClassName}
		value={inputValue}
		onChange={val => {
			handleInputChange(val);
		}}
		type='text'
		placeholder={ph}
		ref={inputRef}
		disabled={props.isLoading}
	/>;

	const element = (): JSX.Element => {
		switch (props.styles) {
			case TextFieldStyles.indiTitle:
				return <div className='textField-indi'>
					<label className='textField-title-label'>{props.label}</label>
					<Space size={10} isAutoResize={false}></Space>
					{inputElement(props.placeholder ?? '')}
				</div>;
			default:
				return <div className='textField-label'>
					{inputElement(props.label)}
				</div>;
		}
	};

	return element();
});

TextField.displayName = 'TextField';

export default TextField;
