import React, {useState, useEffect, type CSSProperties, forwardRef, useImperativeHandle} from 'react';
import './SnackBar.css';

type Props = {
	width?: number;
	height?: number;
	position?: 'top' | 'bottom';
};

type SnackbarProps = {
	show: boolean;
	message: string;
	isError: boolean;
} & Props;

type CustomStyleType = {
	'--width': string;
	'--height': string;
	'--bottom'?: string | number;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

export type SnackBarHandle = {
	trigger: (hasError: boolean, msg: string) => void;
};

const SnackBar = forwardRef<SnackBarHandle, Props>((props, ref) => {
	const data = {width: props.width ?? 50, height: props.height ?? 4, position: props.position ?? 'top'};

	const [showSnackBar, setShowSnackBar] = useState<SnackbarProps['show']>(false);
	const [message, setMessage] = useState<SnackbarProps['message']>('');
	const [isError, setIsError] = useState<SnackbarProps['isError']>(true);

	// UseEffect(() => {
	// 	let timer: NodeJS.Timeout;
	// 	if (showSnackBar) {
	// 		timer = setTimeout(() => {
	// 			setShowSnackBar(false);
	// 			setMessage('');
	// 		}, 2000);
	// 	}

	// 	return () => {
	// 		clearTimeout(timer);
	// 	};
	// }, [showSnackBar]);

	let timer: NodeJS.Timeout | undefined;

	useEffect(() => {
		if (showSnackBar) {
			// Clear any existing timer before setting a new one
			if (timer) {
				clearTimeout(timer);
			}

			timer = setTimeout(() => {
				setShowSnackBar(false);
				setMessage('');
			}, 2000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer); // Clear the timer on component unmount or when showSnackBar changes
			}
		};
	}, [showSnackBar]);

	useImperativeHandle(ref, () => ({
		trigger(hasError: boolean, msg: string) {
			if (!showSnackBar) {
				setIsError(hasError);
				setMessage(msg);
				setShowSnackBar(true);
			}
		},
	}));

	const sbClassName = `snackbar ${isError ? 'invalid' : 'valid'}`;
	const sblClassName = `snackbar-label ${isError ? 'invalid' : 'valid'}`;
	const isBottomPos = data.position === 'bottom' ? 0 : 'auto';

	const customStyle: ExtendedDivStyle = {
		'--width': `${data.width}%`,
		'--height': `${data.height}%`,
		'--bottom': isBottomPos,
	};

	return showSnackBar ? (
		<div className={sbClassName} style={customStyle}>
			<label className={sblClassName}>{message}</label>
		</div>
	) : null;
});

SnackBar.displayName = 'SnackBar';

export default SnackBar;
