import React, {useEffect, useRef, useState} from 'react';
import './Materials.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type MaterialsModel} from '../../models/materials';
import MaterialsService from '../../services/materials';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Materials = () => {
	const [isAddMat, setAddMat] = useState<boolean>(false);
	const [data, setData] = useState<MaterialsModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const note = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchMat();
	}, []);

	async function fetchMat() {
		await new MaterialsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddMat(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = name.current!.validate();
		const field2 = note.current!.validate();
		if (!field1.isValid || !field2.isValid) {
			snackBarRibbon.current!.trigger(true, field1.isValid ? field2.msg : field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid && field2.isValid) {
			setListLoading(true);
			await new MaterialsService().create(name.current!.getValue(), note.current!.getValue()).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchMat();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				name.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddMat) ? 'center' : 'start',
	};

	return (
		<div className='Materials'>
			<div className='Materials-left-panel'>
				<div className='Materials-left-tools'>
					<label className='Materials-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Material'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='Materials-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Materials-list-adapter'>
							<label className='Materials-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Materials-list-adapter-card'>
								<label className='Materials-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='Materials-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as MaterialsModel;
						return <div className='Materials-list-adapter'>
							<label className='Materials-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Materials-list-adapter-card'>
								<label className='Materials-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddMat(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>
			<div className='Materials-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Materials-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddMat && <label className='Materials-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Materials-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Materials-detail-perc'>{data[selItemIndex].name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Materials-detail-perc'>{data[selItemIndex].note}</label>
					</div>}
					{isAddMat && <div>
						<TextField ref={name} isLoading={isListLoading}
							label={'Enter the new material name'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<TextField ref={note} isLoading={isListLoading}
							label={'Enter the note'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={15} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Materials;
