import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './IconButton.css';

type Props = {
	onClick?: (i?: number) => void;
	icon: string;
	hasSplashBg?: boolean;
	iconSize?: number;
	index?: number;
	selIndex?: number;
	bgColor?: string;
};

type Handle = {
	complete: () => boolean;
};

type CustomStyleType = {
	'--iconSize': string;
	'--bgColor': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const IconButton = forwardRef<Handle, Props>((props, ref) => {
	const btnRef = useRef(null);
	const [isLoading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		complete() {
			setLoading(false);
			return isLoading;
		},
	}));

	const handleClick = () => {
		if (!ref) {
			if (props.onClick) {
				props.onClick(props.index);
			}
		}

		if (ref && !isLoading) {
			setLoading(true);
			if (props.onClick) {
				props.onClick();
			}
		}
	};

	const buttonBg = props.hasSplashBg ? props.index === props.selIndex ? 'icon-button-selected' : 'icon-button-unselected' : 'icon-button';
	const image = props.hasSplashBg ? props.index === props.selIndex ? 'icon-btn-img-selected' : 'icon-btn-img-unselected' : 'icon-btn-img';

	const customStyle: ExtendedDivStyle = {
		'--iconSize': props.iconSize ? `${props.iconSize}px` : '15px',
		'--bgColor': props.bgColor ?? 'rgb(251, 137, 16)',
	};

	return (
		<button className={buttonBg} style={customStyle} onClick={handleClick} ref={btnRef}>
			<img className={image} src={props.icon} alt='icon'></img>
		</button>
	);
});

IconButton.displayName = 'IconButton';

export default IconButton;
