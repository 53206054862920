import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './DateTimeFrto.css';
import Space from '../Space/Space';
import TimePicker, {type TimePickerHandle} from '../TimePicker/TimePicker';

type Props = {
	isLoading?: boolean;
	color?: string;
	init?: string;
};

export type DateTimeFrtoHandle = {
	getValue: () => string | undefined;
	setValue: (v: string) => void;
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const months = [
	{mo: 'Jan', id: '01'},
	{mo: 'Feb', id: '02'},
	{mo: 'Mar', id: '03'},
	{mo: 'Apr', id: '04'},
	{mo: 'May', id: '05'},
	{mo: 'Jun', id: '06'},
	{mo: 'Jul', id: '07'},
	{mo: 'Aug', id: '08'},
	{mo: 'Sep', id: '09'},
	{mo: 'Oct', id: '10'},
	{mo: 'Nov', id: '11'},
	{mo: 'Dec', id: '12'},
];

const DateTimeFrto = forwardRef<DateTimeFrtoHandle, Props>((props, ref) => {
	const sTimeRef = useRef<TimePickerHandle>(null);
	const eTimeRef = useRef<TimePickerHandle>(null);
	const mainref = useRef<HTMLDivElement>(null);
	const dateStRef = useRef<HTMLInputElement>(null);
	const dateEtRef = useRef<HTMLInputElement>(null);
	const [selStDate, setSelStDate] = useState<string>('---- -- --');
	const [selEtDate, setSelEtDate] = useState<string>('---- -- --');
	const [initVal, setInitVal] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (props.init) {
			setInitVal(props.init);
		}
	}, [props.init]);

	useImperativeHandle(ref, () => ({
		getValue() {
			return result();
		},

		setValue(v: string) {
			setInitVal(v);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const result = () => {
		if (props.isLoading !== true) {
			if (!sTimeRef.current || !eTimeRef.current) {
				return undefined;
			}

			const newStRaw = sTimeRef.current.pickedTime();
			const newEtRaw = eTimeRef.current.pickedTime();

			const newStime = parseFloat(sTimeRef.current.pickedTime().replace(':', '.'));
			const newEtime = parseFloat(eTimeRef.current.pickedTime().replace(':', '.'));

			if (selStDate === '---- -- --' || selEtDate === '---- -- --') {
				return undefined;
			}

			if (selStDate === selEtDate) {
				if (newStime === 0 || newEtime === 0) {
					return undefined;
				}

				if (newStime === newEtime || newStime < newEtime) {
					return undefined;
				}
			}

			// 2023:10:28-12:00-2023:10:30-11:00
			// 2023:10:05-05:10-2023:10:18-20:09
			// Thu Oct 05 2023-05:06:00-Wed Oct 18 2023-020:09:00
			const startTimeCon = `${newStRaw.split(':')[0].length === 1 ? `0${newStRaw.split(':')[0]}` : newStRaw.split(':')[0]}:${newStRaw.split(':')[1].length === 1 ? `0${newStRaw.split(':')[1]}` : newStRaw.split(':')[1]}`;
			const endTimeCon = `${newEtRaw.split(':')[0].length === 1 ? `0${newEtRaw.split(':')[0]}` : newEtRaw.split(':')[0]}:${newEtRaw.split(':')[1].length === 1 ? `0${newEtRaw.split(':')[1]}` : newEtRaw.split(':')[1]}`;

			const stD = `${selStDate.replaceAll('-', ':').split(' ')[3]}:${months.find(f => f.mo === selStDate.replaceAll('-', ':').split(' ')[1])!.id}:${selStDate.replaceAll('-', ':').split(' ')[2]}`;
			const etD = `${selEtDate.replaceAll('-', ':').split(' ')[3]}:${months.find(f => f.mo === selEtDate.replaceAll('-', ':').split(' ')[1])!.id}:${selEtDate.replaceAll('-', ':').split(' ')[2]}`;

			return `${stD}-${startTimeCon}-${etD}-${endTimeCon}`;
		}
	};

	const initReVal = (isSt: boolean): string | undefined => {
		if (initVal) {
			setSelStDate(initVal.split('-')[0]);
			setSelEtDate(initVal.split('-')[2]);

			// 2023:10:28-12:00-2023:10:30-11:00
			const st = initVal.split('-')[1];
			const et = initVal.split('-')[3];
			if (isSt) {
				return st;
			}

			return et;
		}
	};

	const month = new Date().getMonth() + 1;
	const date = new Date().getDate();
	const today = `${new Date().getFullYear()}-${month.toString().length === 1 ? '0' + month : month}-${date.toString().length === 1 ? '0' + date : date}`;

	return (
		<div className='DateTimeFrto' tabIndex={0} ref={mainref}>
			<div className='DateTimeFrto-cont'>
				<div className='DateTimeFrto-cont-col'>
					<div className='DateTimeFrto-cont'>
						<label className='DateTimeFrto-label'>Start On</label>
						<Space size={5} isAutoResize={false}></Space>
						<input ref={dateStRef} className='DateTimeFrto-date' type='date' min={today} onChange={() => {
							setSelStDate(dateStRef.current!.valueAsDate!.toDateString());
							if (!navigator.userAgent.includes('Chrome')) {
								dateStRef.current!.type = 'text';
								dateStRef.current!.type = 'date';
							}
						}}></input>
						<div className='DateTimeFrto-date-pick' onClick={() => {
							if (props.isLoading !== true) {
								dateStRef.current!.focus();
								dateStRef.current!.showPicker();
								dateStRef.current!.click();
							}
						}}>{selStDate}</div>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<TimePicker ref={sTimeRef} initValue={initReVal(true)} isLoading={props.isLoading === true} onChange={() => {
						//
					}}></TimePicker>
				</div>
				<Space size={40} isAutoResize={false}></Space>
				<div className='DateTimeFrto-cont-col'>
					<div className='DateTimeFrto-cont'>
						<label className='DateTimeFrto-label'>End On</label>
						<Space size={5} isAutoResize={false}></Space>
						<input ref={dateEtRef} className='DateTimeFrto-date' type='date' min={today} onChange={() => {
							setSelEtDate(dateEtRef.current!.valueAsDate!.toDateString());
							if (!navigator.userAgent.includes('Chrome')) {
								dateEtRef.current!.type = 'text';
								dateEtRef.current!.type = 'date';
							}
						}}></input>
						<div className='DateTimeFrto-date-pick' onClick={() => {
							if (props.isLoading !== true) {
								dateEtRef.current!.focus();
								dateEtRef.current!.showPicker();
								dateEtRef.current!.click();
							}
						}}>{selEtDate}</div>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<TimePicker ref={eTimeRef} initValue={initReVal(false)} isLoading={props.isLoading === true} onChange={() => {
						//
					}}></TimePicker>
				</div>
			</div>
		</div>

	);
});

DateTimeFrto.displayName = 'DateTimeFrto';

export default DateTimeFrto;
