import React, {type ReactNode, useEffect, useRef, useState} from 'react';
import './Foods.css';
import LabelButton from '../../components/LabelButton/LabelButton';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import Categories from '../Categories/Categories';
import Customizations from '../Customizations/Customizations';
import Addons from '../Addons/Addons';
import Menus from '../Menus/Menus';
import Materials from '../Materials/Materials';
import AddFood from '../AddFood/AddFood';
import FoodsService from '../../services/foods';
import {type ResFood, type ResVariations, type ResIngredients} from '../../models/foods';
import ListView from '../../components/ListView/ListView';
import Space from '../../components/Space/Space';
import HorButtons from '../../components/HorButtons/HorButtons';
import {type CustomizationModel} from '../../models/customizations';
import {type AddonModel} from '../../models/addons';
import RestaurantsService from '../../services/restaurants';
import {type RestaurantModel} from '../../models/restaurants';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import CheckBox from '../../components/CheckBox/CheckBox';
import DayTimePicker, {type DayTimePickerHandle} from '../../components/DayTimePicker/DayTimePicker';
import Button from '../../components/Button/Button';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import TextFieldStyles from '../../utils/textfield/fieldstyles';
import DateTimeFrto, {type DateTimeFrtoHandle} from '../../components/DateTimeFrto/DateTimeFrto';
import {useAuthContext} from '../../context/authContext';

const Foods = () => {
	const authRes = useAuthContext();

	const [selMenuIndex, setMenuIndex] = useState<number>(1);
	const [isLoading, setLoading] = useState<boolean>(true);
	const [isFoodUpdating, setFoodUpdating] = useState<boolean>(false);
	const [isFoodLoading, setFoodLoading] = useState<boolean>(false);
	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const [foodsData, setFoodsData] = useState<ResFood[]>([]);
	const [restData, setRestData] = useState<RestaurantModel[]>([]);
	const [selRest, setSelRest] = useState<number>(-1);
	const [selFood, setSelFood] = useState<number>(-1);
	const [selVari, setSelVari] = useState<number>(-1);
	const [selVariUpdate, setSelVariUpdate] = useState<number[]>([]);
	const availRef = useRef<DayTimePickerHandle>(null);
	const [isAvailSame, setIsAvailSame] = useState<boolean>(false);
	const [isStockSame, setIsStockSame] = useState<boolean>(false);
	// Const [hasStock, setHasStock] = useState<boolean>(false);
	const noOfStockRef = useRef<TextFieldHandle>(null);
	const [isOfferSame, setIsOfferSame] = useState<boolean>(false);
	const disPriceVarField = useRef<TextFieldHandle>(null);
	const offNoteVarField = useRef<TextFieldHandle>(null);
	const offValiRef = useRef<DateTimeFrtoHandle>(null);
	const [hasOffer, setHasOffer] = useState<boolean>(false);
	const [isPercentage, setIsPercentage] = useState<boolean>(false);
	const [isDelist, setIsDelist] = useState<boolean>(false);
	const [isDelistSame, setIsDelistSame] = useState<boolean>(false);

	useEffect(() => {
		void fetchRests();
	}, []);

	useEffect(() => {
		if (selVariUpdate.length === 1) {
			availRef.current!.setValue(foodsData[selFood].variations[selVariUpdate[0]].availability);
		}
	}, [selVariUpdate]);

	// DATA --->

	async function fetchRests() {
		if (!isLoading) {
			setLoading(true);
		}

		await new RestaurantsService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setRestData(val.res!.data!);
			}
		}).finally(() => {
			setLoading(false);
		});
	}

	async function fetchFoods(restIndex: number, reset: boolean) {
		if (restIndex !== -1) {
			if (!isFoodLoading) {
				setFoodLoading(true);
			}

			const restId = restData[restIndex]._id;
			await new FoodsService().get(restId).then(val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					if (reset) {
						clearOnEventChanged(1);
					}

					setFoodsData(val.res!.data!);
				}
			}).finally(() => {
				setFoodLoading(false);
			});
		}
	}

	// FUNCTIONS --->

	const handleOnMenuClick = (i?: number) => {
		// Reset the data
		if (i === 1) {
			clearOnEventChanged(2);
			void fetchRests();
		}

		if (selMenuIndex !== i) {
			setMenuIndex(i!);
		}
	};

	const setSelVarForUpdate = (i: number) => {
		if (!isLoading) {
			if (selVariUpdate.includes(i)) {
				setSelVariUpdate(selVariUpdate.filter(v => v !== i));
			} else {
				setSelVariUpdate([...selVariUpdate, i]);
			}
		}
	};

	const clearOnEventChanged = (i: number) => {
		if (i === 1 || i === 2) {
			// Fetch food or On menu clicked
			setSelFood(-1);
		}

		if (i === 2) {
			// On menu clicked
			setSelRest(-1);
			setFoodsData([]);
		}

		// Another food selected and comman
		setSelVari(-1);
		if (availRef.current) {
			availRef.current.setValue([]);
			setIsAvailSame(false);
			setIsStockSame(false);
			// SetHasStock(false);
		}

		setSelVariUpdate([]);
	};

	const variation = ((selRest !== -1)
		&& selFood !== -1 && selVari !== -1) ? foodsData[selFood].variations[selVari] : undefined;
	const loadState = !isLoading && !isFoodLoading;

	const variPage = () => variation && <div>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Discount Price : ${variation.discountPrice}`}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{variation.hasTax ? variation.tax.useOverallTax ? 'Tax : Overall tax' : `Tax : ${variation.inditax!.percentage}%` : ''}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Serving Info : ${variation.servingInfo}`}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Calorie Count : ${variation.calorieCount}`}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Portion Size : ${variation.portionSize}`}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Preparation Time : ${variation.preparationTime}`}</label>
		<Space size={10} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>Offer</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Validity : ${variation.offer.offerValidity}`}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`Note : ${variation.offer.offerNote}`}</label>
		<Space size={10} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{variation.hasStock ? 'In Stock' : 'Out of Stock'}</label>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>{`No of Stocks : ${variation.noOfStock}`}</label>
		{variation.isDelisted && <div>
			<Space size={5} isAutoResize={false}></Space>
			<label className='rest-main-listitem-adapter-title' style={{color: 'red'}}>{variation.isDelisted ? 'Delisted' : ''}</label>
			<Space size={5} isAutoResize={false}></Space>
			<label className='rest-main-listitem-adapter-title' style={{color: 'red'}}>{`Delisted At : ${variation.delistedAt}`}</label>
		</div>}
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>Customizations</label>
		<Space size={5} isAutoResize={false}></Space>
		<div className='listview-wrapper'>
			<ListView data={variation.customizations ? variation.customizations : []} adapter={function (i: number, item: CustomizationModel | number): JSX.Element {
				const itemData = item as CustomizationModel;
				return <div className='listview-item-adapter'>
					<label className='listview-item-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='listview-item-adapter-card'>
						<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
					</div>
				</div>;
			}} adapterHeight={35} />
		</div>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>Addons</label>
		<Space size={5} isAutoResize={false}></Space>
		<div className='listview-wrapper'>
			<ListView data={variation.addons ? variation.addons : []} adapter={function (i: number, item: AddonModel | number): JSX.Element {
				const itemData = item as AddonModel;
				return <div className='listview-item-adapter'>
					<label className='listview-item-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='listview-item-adapter-card'>
						<label className='listview-item-adapter-title'>{`${itemData.name}`}</label>
					</div>
				</div>;
			}} adapterHeight={35} />
		</div>
		<Space size={5} isAutoResize={false}></Space>
		<label className='rest-main-listitem-adapter-title'>Ingredients</label>
		<Space size={5} isAutoResize={false}></Space>
		<div className='listview-wrapper'>
			<ListView data={variation.ingredients ? variation.ingredients : []} adapter={function (i: number, item: ResIngredients | number): JSX.Element {
				const itemData = item as ResIngredients;
				return <div className='listview-item-adapter'>
					<label className='listview-item-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='listview-item-adapter-card'>
						<label className='listview-item-adapter-title'>{`${itemData.material.name}`}</label>
					</div>
				</div>;
			}} adapterHeight={35} />
		</div>
	</div>;

	const mainPage = () => <div className='Restaurants-main'>
		<div className='rest-main-left-container' style={{width: '20%'}}>
			{isLoading ? (<ListView dummy={5} adapter={function (i, item): JSX.Element {
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index-load'>00</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
						<Space size={10} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-title-load'>{'Updated : ${}'}</label>
					</div>
				</div>;
			}} selItemIndex={-1} adapterHeight={80} />) : (<ListView data={restData} adapter={function (i, item): JSX.Element {
				const itemData = item as RestaurantModel;
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.address}</label>
					</div>
				</div>;
			}} onSelectItem={i => {
				if (selRest !== i) {
					setSelRest(i);
					void fetchFoods(i, true);
				}
			}} selItemIndex={selRest} adapterHeight={80} />)}
		</div>
		<div className='rest-main-left-container' style={{width: '20%', background: 'none'}}>
			{loadState ? <ListView data={foodsData} adapter={function (i, item): JSX.Element {
				const itemData = item as ResFood;
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index'>{i + 1}</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title'>{itemData.name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>{itemData.note}</label>
					</div>
				</div>;
			}} onSelectItem={i => {
				// Reset the variation selection to avoid error while select another food
				clearOnEventChanged(0);
				setSelFood(i);
			}} selItemIndex={selFood} adapterHeight={50} /> : (<ListView dummy={5} adapter={function (i, item): JSX.Element {
				return <div className='rest-main-listview'>
					<label className='rest-main-listitem-adapter-index-load'>00</label>
					<Space size={5} isAutoResize={true}></Space>
					<div className='rest-main-listitem-adapter-card'>
						<label className='rest-main-listitem-adapter-title-load'>{'Tax Percentage : ${}%'}</label>
					</div>
				</div>;
			}} selItemIndex={-1} adapterHeight={50} />)}
		</div>
		<div className='rest-main-right-container'>
			{(loadState && selFood !== -1) ? (<div className='rest-main-right-container-cont'>
				<Space size={40} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{foodsData[selFood].name}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-subtitle'>{foodsData[selFood].note}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{foodsData[selFood].isCombo ? 'Combo Food' : foodsData[selFood].isFreeClaim ? 'FreeClaim Food' : ''}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Validity : ${foodsData[selFood].isCombo ? foodsData[selFood].combo.comboValidity : foodsData[selFood].isFreeClaim ? foodsData[selFood].freeClaim.freeValidity : ''}`}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Condition : ${foodsData[selFood].isFreeClaim ? foodsData[selFood].freeClaim.condition!.name : ''}`}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Coalitions : ${foodsData[selFood].coalitions.toString()}`}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Menus : ${foodsData[selFood].menus.toString()}`}</label>
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Category : ${foodsData[selFood].category}`}</label>
				{foodsData[selFood].isDelisted && <div>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title' style={{color: 'red'}}>{foodsData[selFood].isDelisted ? 'Delisted' : ''}</label>
					<Space size={10} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title' style={{color: 'red'}}>{`Delisted At : ${foodsData[selFood].delistedAt}`}</label>
				</div>}
				<Space size={10} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>{`Services : ${foodsData[selFood].restTypes.map(v => v.name).toString()}`}</label>
				<Space size={15} isAutoResize={false}></Space>
				<HorButtons data={foodsData[selFood].images} adapter={(i, item: string) => <div className='foods-image-view'>
					<img src={item} height={55}></img>
				</div>} adapterWidth={100}></HorButtons>
				<Space size={20} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Variations</label>
				<Space size={10} isAutoResize={false}></Space>
				<HorButtons data={foodsData[selFood].variations} adapter={(i, item: ResVariations) => <div className='food-container-column' style={{border: '1px solid rgb(214, 223, 231)', borderRadius: '4px', height: '87px', padding: '15px'}}>
					<label className='rest-main-listitem-adapter-title'>{item.name}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Price : ${item.price}`}</label>
					<Space size={5} isAutoResize={false}></Space>
					<label className='rest-main-listitem-adapter-title'>{`Discount Price : ${item.discountPrice}`}</label>
					<Space size={10} isAutoResize={false}></Space>
					<div className='food-container-row'>
						<OutlineButton onClick={function (): void {
							setSelVari(i);
						}} label={'Details'}></OutlineButton>
						<Space size={5} isAutoResize={false}></Space>
						{item.isDefault && <OutlineButton onClick={function (): void {
							//
						}} label={'Default'} btnColor='blue'></OutlineButton>}
						<Space size={5} isAutoResize={false}></Space>
						<CheckBox title={'Selected'} initVal={selVariUpdate.includes(i)} ovrVal={selVariUpdate.includes(i)} onClick={(b: boolean) => {
							setSelVarForUpdate(i);
						}}></CheckBox>
					</div>
				</div>} adapterWidth={300} adapterHeight={120} selItemIndex={selVari} onSelectItem={(j: number) => {
					setSelVarForUpdate(j);
				}}></HorButtons>
				<Space size={15} isAutoResize={false}></Space>
				<div className='food-container-row' style={{alignItems: 'center'}}>
					<div className='food-container-column'>
						<label className='rest-main-listitem-adapter-title'>Food Availability</label>
						<Space size={5} isAutoResize={false}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>used determine the availability of the food, like.., this food var available only at night</label>
						<Space size={10} isAutoResize={false}></Space>
						<DayTimePicker ref={availRef} isLoading={isFoodUpdating}></DayTimePicker>
					</div>
					<Space size={20} isAutoResize={false}></Space>
					<div className='food-container-column'>
						<CheckBox title={'Is same to all?'} initVal={isAvailSame} isLoading={isFoodUpdating} onClick={(b: boolean) => {
							setIsAvailSame(b);
						}}></CheckBox>
						<Space size={5} isAutoResize={false}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>when choose to all, this availability will apply to all food variations</label>
						<Space size={15} isAutoResize={false}></Space>
						{/* <Button onClick={function (): void {
							if (!isFoodUpdating) {
								void updateAvailability();
							}
						}} isLoading={isFoodUpdating} label={'Update'}></Button> */}
					</div>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Food Stock</label>
				<Space size={10} isAutoResize={false}></Space>
				<div className='food-container-row'>
					<div className='food-container-column'>
						<CheckBox title={'Is same to all?'} initVal={isStockSame} isLoading={isFoodUpdating} onClick={(b: boolean) => {
							setIsStockSame(isStockSame);
						}}></CheckBox>
						<Space size={5} isAutoResize={false}></Space>
						<label className='rest-main-listitem-adapter-subtitle'>when choose to all, this stock will apply to all food variations</label>
						{/* <Space size={10} isAutoResize={false}></Space>
						<CheckBox title={'Has stock?'} initVal={hasStock} isLoading={isFoodUpdating} onClick={(b: boolean) => {
							setHasStock(hasStock);
						}}></CheckBox> */}
					</div>
					<Space size={20} isAutoResize={false}></Space>
					<div className='food-container-column'>
						<TextField ref={noOfStockRef} inputType={TextFieldInputTypes.number} label={'Enter no of stock'} isLoading={isFoodUpdating}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						{/* <Button onClick={function (): void {
							if (!isFoodUpdating) {
								void updateStock();
							}
						}} isLoading={isFoodUpdating} label={'Update'}></Button> */}
					</div>
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Offer</label>
				<Space size={10} isAutoResize={false}></Space>
				{hasOffer && <div className='food-container-column'>
					<div className='food-container-row'>
						<CheckBox title={'Is percentage?'} initVal={isPercentage} isLoading={isFoodUpdating} onClick={(b: boolean) => {
							setIsPercentage(isPercentage);
						}}></CheckBox>
						<Space size={15} isAutoResize={false}></Space>
						<TextField ref={disPriceVarField} isLoading={isLoading}
							label={'Discount Price'} styles={TextFieldStyles.indiTitle} placeholder='I.e: 20.5'
							inputType={TextFieldInputTypes.floatnumber}></TextField>
						<Space size={15} isAutoResize={false}></Space>
						<TextField ref={offNoteVarField} isLoading={isLoading}
							label={'Offer Note'} styles={TextFieldStyles.indiTitle} placeholder='I.e: something about the offer'
							inputType={TextFieldInputTypes.name}></TextField>
					</div>
					<Space size={10} isAutoResize={false}></Space>
					<label className='AddFood-label'>Offer Validity</label>
					<Space size={10} isAutoResize={false}></Space>
					<DateTimeFrto ref={offValiRef}></DateTimeFrto>
					<Space size={15} isAutoResize={false}></Space>
				</div>}
				<div className='AddFood-container-row'>
					<CheckBox title={'has offer?'} initVal={hasOffer} onClick={(b: boolean) => {
						if (!isLoading) {
							setHasOffer(b);
						}
					}}></CheckBox>
					<Space size={15} isAutoResize={false}></Space>
					<CheckBox title={'Is same to all?'} initVal={isOfferSame} isLoading={isFoodUpdating} onClick={(b: boolean) => {
						setIsOfferSame(isOfferSame);
					}}></CheckBox>
					<Space size={15} isAutoResize={false}></Space>
					{/* <Button onClick={function (): void {
						if (!isFoodUpdating) {
							void updateOffer();
						}
					}} isLoading={isFoodUpdating} label={'Update'}></Button> */}
				</div>
				<Space size={15} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-title'>Delist</label>
				<Space size={5} isAutoResize={false}></Space>
				<label className='rest-main-listitem-adapter-subtitle'>delist will remove the food from entire restaurant</label>
				<Space size={10} isAutoResize={false}></Space>
				<div className='AddFood-container-row'>
					<CheckBox title={'Delist?'} initVal={isDelist} isLoading={isFoodUpdating} onClick={(b: boolean) => {
						setIsDelist(isDelist);
					}}></CheckBox>
					<Space size={10} isAutoResize={false}></Space>
					<CheckBox title={'Is same to all?'} initVal={isDelistSame} isLoading={isFoodUpdating} onClick={(b: boolean) => {
						setIsDelistSame(isDelistSame);
					}}></CheckBox>
					<Space size={15} isAutoResize={false}></Space>
					{/* <Button onClick={function (): void {
						if (!isFoodUpdating) {
							void updateDelist();
						}
					}} isLoading={isFoodUpdating} label={'Update'}></Button> */}
				</div>
				<Space size={15} isAutoResize={false}></Space>
				{variPage()}
				<Space size={50} isAutoResize={false}></Space>
			</div>) : (<div className='rest-main-right-container-cont' style={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
				<label className='rest-main-listitem-adapter-subtitle' style={{textAlign: 'center'}}>Select any restaurant to get the food</label>
			</div>)}
		</div>
	</div>;

	const pageByRole = (page: JSX.Element): ReactNode => page;

	const buttonByRole = (button: JSX.Element): ReactNode => button;

	return (
		<div className='Foods'>
			<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
			<div className='foods-menu-bar'>
				<LabelButton onClick={handleOnMenuClick} index={1} selIndex={selMenuIndex} label={'Foods'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={2} selIndex={selMenuIndex} label={'Add Food'}></LabelButton>
				{buttonByRole(<LabelButton onClick={handleOnMenuClick} index={3} selIndex={selMenuIndex} label={'Categories'}></LabelButton>)}
				{buttonByRole(<LabelButton onClick={handleOnMenuClick} index={4} selIndex={selMenuIndex} label={'Menus'}></LabelButton>)}
				<LabelButton onClick={handleOnMenuClick} index={5} selIndex={selMenuIndex} label={'Customizations'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={6} selIndex={selMenuIndex} label={'Addons'}></LabelButton>
				<LabelButton onClick={handleOnMenuClick} index={8} selIndex={selMenuIndex} label={'Materials'}></LabelButton>
			</div>
			<div className='foods-menu-content'>{(() => {
				switch (selMenuIndex) {
					case 1:
						return mainPage();
					case 2:
						return <AddFood />;
					case 3:
						return pageByRole(<Categories />);
					case 4:
						return pageByRole(<Menus />);
					case 5:
						return <Customizations />;
					case 6:
						return <Addons />;
					case 8:
						return <Materials />;
					default:
						return <div />;
				}
			})()}</div>
		</div >
	);
};

export default Foods;
