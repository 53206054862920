import React, {useRef, useImperativeHandle, forwardRef, useState, useEffect} from 'react';
import './DayTimePicker.css';
import Space from '../Space/Space';
import TimePicker, {type TimePickerHandle} from '../TimePicker/TimePicker';

type Props = {
	isLoading?: boolean;
	init?: Array<{
		day: string;
		st: number;
		et: number;
	}>;
};

export type DayTimePickerHandle = {
	getValue: () => Array<{
		day: string;
		st: number;
		et: number;
	}>;
	setValue: (v: Array<{
		day: string;
		st: number;
		et: number;
	}>) => void;
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const DayTimePicker = forwardRef<DayTimePickerHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLButtonElement>(null);
	const startTimeRefs = Array.from({length: 7}, () => useRef<TimePickerHandle>(null));
	const endTimeRefs = Array.from({length: 7}, () => useRef<TimePickerHandle>(null));
	const [isTiValid, setIsTiValid] = useState(() =>
		Array.from({length: 7}, () => false),
	);
	const [selInitVal, setInitVal] = useState<Array<{
		day: string;
		st: number;
		et: number;
	}>>();
	const mainref = useRef<HTMLDivElement>(null);

	const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

	useEffect(() => {
		if (props.init) {
			setInitVal(props.init);
		}
	}, [props.init]);

	useImperativeHandle(ref, () => ({
		getValue() {
			const result = [];
			for (let i = 0; i < isTiValid.length; i++) {
				if (isTiValid[i]) {
					const st = startTimeRefs[i].current!.pickedTime().replace(':', '.');
					const et = endTimeRefs[i].current!.pickedTime().replace(':', '.');
					result.push({
						day: days[i].toLowerCase(),
						st: parseFloat(st),
						et: parseFloat(et),
					});
				}
			}

			return result;
		},

		setValue(v) {
			setInitVal(v);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const onChangeTime = (i: number): void => {
		isTiValid.findIndex(v => v);
		if (!endTimeRefs[i].current || !startTimeRefs[i].current) {
			setIsTiValid(prevIsTiValid =>
				prevIsTiValid.map((value, j) => (j === i ? false : value)),
			);
		}

		const sTime: string = startTimeRefs[i].current!.pickedTime();
		const eTime: string = endTimeRefs[i].current!.pickedTime();

		if (Number(sTime.split(':')[0]) !== 0 && (Number(sTime.split(':')[0]) === Number(eTime.split(':')[0])) && (Number(sTime.split(':')[1]) < Number(eTime.split(':')[1]))) {
			setIsTiValid(prevIsTiValid =>
				prevIsTiValid.map((value, j) => (j === i ? true : value)),
			);
		} else if (Number(sTime.split(':')[0]) !== 0 && Number(sTime.split(':')[0]) < Number(eTime.split(':')[0])) {
			setIsTiValid(prevIsTiValid =>
				prevIsTiValid.map((value, j) => (j === i ? true : value)),
			);
		} else {
			setIsTiValid(prevIsTiValid =>
				prevIsTiValid.map((value, j) => (j === i ? false : value)),
			);
		}
	};

	const initVal = (day: string, isSt: boolean): string | undefined => {
		if (selInitVal) {
			const value = selInitVal.find(v => v.day === day.toLowerCase());
			if (value) {
				const st = value.st.toString().replace('.', ':');
				const et = value.et.toString().replace('.', ':');
				if (isSt) {
					return st;
				}

				return et;
			}
		}
	};

	return (
		<div className='DayTimePicker' tabIndex={0} ref={mainref}>
			{days.map((day: string, i: number) => {
				const customStyle: ExtendedDivStyle = {
					'--marBottom': days.length === (i + 1) ? '0' : '2px',
					'--isActive': isTiValid[i] ? 'rgba(9, 181, 49, 0.919)' : 'rgba(255, 10, 63, 0.919)',
				};

				return (<div key={i} style={customStyle} className='daytimpick-holder'>
					<button className='daytimepick-button' ref={btnRef}>
						{day}
					</button>
					<Space size={10} isAutoResize={false}></Space>
					<label className='daytimepick-label'>start at</label>
					<Space size={5} isAutoResize={false}></Space>
					<TimePicker ref={startTimeRefs[i]} initValue={initVal(day, true)} isLoading={props.isLoading === true} onChange={() => {
						if (props.isLoading !== true) {
							onChangeTime(i);
						}
					}}></TimePicker>
					<Space size={10} isAutoResize={false}></Space>
					<label className='daytimepick-label'>end at</label>
					<Space size={5} isAutoResize={false}></Space>
					<TimePicker ref={endTimeRefs[i]} initValue={initVal(day, false)} isLoading={props.isLoading === true} onChange={() => {
						if (props.isLoading !== true) {
							onChangeTime(i);
						}
					}}></TimePicker>
				</div>);
			})}
		</div>

	);
});

DayTimePicker.displayName = 'DayTimePicker';

export default DayTimePicker;
