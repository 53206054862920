class RestaurantSvg {
	svgRaw = `<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.4064 8.57908L25.052 5.19388C24.5387 1.50317 22.8644 0 19.2838 0H16.3996H14.591H10.8759H9.0671H6.13408C2.54114 0 0.879095 1.50317 0.353597 5.23055L0.0236321 8.5913C-0.0985769 9.89893 0.255829 11.1699 1.02575 12.1598C1.95454 13.3697 3.38438 14.054 4.9731 14.054C6.51293 14.054 7.99166 13.2841 8.92045 12.0498C9.75147 13.2841 11.1692 14.054 12.7457 14.054C14.322 14.054 15.7031 13.3208 16.5462 12.0987C17.4874 13.3086 18.9415 14.054 20.4569 14.054C22.0823 14.054 23.5488 13.333 24.4654 12.062C25.1986 11.0844 25.5287 9.85005 25.4064 8.57908Z" fill="#CF4D4D"/>
    <path d="M11.9506 18.8327C10.3985 18.9915 9.22534 20.3114 9.22534 21.8757V25.2242C9.22534 25.5541 9.4942 25.823 9.82417 25.823H15.6535C15.9835 25.823 16.2523 25.5541 16.2523 25.2242V22.3034C16.2645 19.7492 14.7614 18.5394 11.9506 18.8327Z" fill="#CF4D4D"/>
    <path d="M24.1981 16.0682V19.71C24.1981 23.083 21.4606 25.8204 18.0877 25.8204C17.7577 25.8204 17.4888 25.5516 17.4888 25.2216V22.3008C17.4888 20.7366 17.0122 19.5145 16.0834 18.6834C15.2646 17.938 14.1525 17.5713 12.7716 17.5713C12.466 17.5713 12.1605 17.5836 11.8306 17.6202C9.65518 17.8402 8.00536 19.6733 8.00536 21.8731V25.2216C8.00536 25.5516 7.7365 25.8204 7.40653 25.8204C4.03356 25.8204 1.29608 23.083 1.29608 19.71V16.0926C1.29608 15.2371 2.13932 14.6628 2.93368 14.9438C3.26365 15.0538 3.59361 15.1394 3.9358 15.1883C4.08245 15.2127 4.24132 15.2371 4.38797 15.2371C4.5835 15.2616 4.77904 15.2738 4.97457 15.2738C6.3922 15.2738 7.78538 14.7483 8.88526 13.844C9.93626 14.7483 11.3051 15.2738 12.7471 15.2738C14.2014 15.2738 15.5457 14.7728 16.5967 13.8684C17.6966 14.7605 19.0653 15.2738 20.4585 15.2738C20.6785 15.2738 20.8985 15.2616 21.1062 15.2371C21.2529 15.2249 21.3873 15.2127 21.5217 15.1883C21.9006 15.1394 22.2428 15.0294 22.5849 14.9194C23.3793 14.6505 24.1981 15.2371 24.1981 16.0682Z" fill="#CF4D4D"/>
    </svg>`;

	replaceColor = '#CF4D4D';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default RestaurantSvg;
