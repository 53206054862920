import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './TimeSlots.css';
import Space from '../Space/Space';
import TimePicker, {type TimePickerHandle} from '../TimePicker/TimePicker';
import ListView from '../ListView/ListView';
import Button, {type ButtonHandle} from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import Delete from '../../assets/icons/delete';

type Props = {
	isLoading?: boolean;
	color?: string;
};

export type TimeSlotsHandle = {
	times: () => string;
	setTimes: (timmm: string) => void;
	clear: () => void;
	focus: () => void;
};

type CustomStyleType = {
	'--marBottom': string;
	'--isActive': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

type Times = {
	time: string;
};

const TimeSlots = forwardRef<TimeSlotsHandle, Props>((props, ref) => {
	const [times, setTimes] = useState<Times[]>([]);
	const timeRef = useRef<TimePickerHandle>(null);
	const onAddBtn = useRef<ButtonHandle>(null);
	const mainref = useRef<HTMLDivElement>(null);
	const delIcon = new Delete();

	useImperativeHandle(ref, () => ({
		times() {
			let result = '';
			for (const time of times) {
				const tim = time.time.split(':').map(v => v.length === 1 ? `0${v}` : v).toString().replace(',', ':');
				result = result.length === 0 ? `${tim}` : `${result}|${tim}`;
			}

			return result;
		},

		setTimes(timmm: string) {
			if (timmm.length === 0) {
				setTimes([]);
			} else {
				const tim = timmm.split('|').map(v => ({
					time: v,
				}));
				setTimes(tim);
			}
		},

		clear() {
			setTimes([]);
		},

		focus() {
			mainref.current!.focus();
		},
	}));

	const handleAddTime = () => {
		if (props.isLoading !== true) {
			onAddBtn.current?.complete();
			const newTime = timeRef.current!.pickedTime();
			if (newTime !== '0:0' && !times.map(v => v.time).includes(newTime)) {
				setTimes([...times, {time: newTime}]);
			}
		}
	};

	const handleDelTime = (i: number) => {
		if (props.isLoading !== true) {
			onAddBtn.current?.complete();
			if (times[i]) {
				setTimes(times.filter(v => v !== times[i]));
			}
		}
	};

	return (
		<div className='TimeSlots' tabIndex={0} ref={mainref}>
			<div className='TimeSlots-cont'>
				<TimePicker ref={timeRef} isLoading={props.isLoading === true} onChange={() => {
					//
				}}></TimePicker>
				<Space size={20} isAutoResize={false}></Space>
				<Button ref={onAddBtn} label={'Add'} onClick={handleAddTime}></Button>
			</div>
			<Space size={20} isAutoResize={false}></Space>
			<div className='TimeSlots-listview'><ListView data={times} adapter={function (i: number, item: Times | number): JSX.Element {
				const itemData = item as Times;
				return <div className='TimeSlots-list-adapter'>
					<label className='TimeSlots-list-adapter-index'>{i + 1}</label>
					<div className='TimeSlots-list-adapter-card'>
						<label className='TimeSlots-list-adapter-title'>{`Time : ${itemData.time}`}</label>
						<Space size={10} isAutoResize={true}></Space>
						<IconButton hasSplashBg={true} icon={delIcon.white()} bgColor='rgb(251, 16, 51)' iconSize={10} onClick={() => {
							handleDelTime(i);
						}}></IconButton>
						<Space size={10} isAutoResize={false}></Space>
					</div>
				</div>;
			}} adapterHeight={35} /></div>
		</div>

	);
});

TimeSlots.displayName = 'TimeSlots';

export default TimeSlots;
