import React, {useEffect, useRef, useState} from 'react';
import './Customizations.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import {type CustomizationModel} from '../../models/customizations';
import CustomizationService from '../../services/customizations';
import ListSlots, {type ListSlotsHandle} from '../../components/ListSlots/ListSlots';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Customizations = () => {
	const [isAddCus, setAddCus] = useState<boolean>(false);
	const [data, setData] = useState<CustomizationModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);
	const answs = useRef<ListSlotsHandle>(null);

	useEffect(() => {
		void fetchCustoms();
	}, []);

	async function fetchCustoms() {
		await new CustomizationService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddCus(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = name.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid) {
			setListLoading(true);

			if (answs.current!.data().length === 0) {
				snackBarRibbon.current!.trigger(true, 'Add the options');
				answs.current!.focus();
				setListLoading(false);
			} else {
				await new CustomizationService().create(name.current!.getValue()!, answs.current!.data()).then(async val => {
					if (val.hasError) {
						snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
					} else {
						await fetchCustoms();

						snackBarRibbon.current!.trigger(false, val.res!.message);
					}

					onSaveBtn.current!.complete();
					name.current!.clearInput();
				});
			}
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddCus) ? 'center' : 'start',
	};

	return (
		<div className='Customizations'>
			<div className='customizations-left-panel'>
				<div className='customizations-left-tools'>
					<label className='customizations-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Custom'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='customizations-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='customizations-list-adapter'>
							<label className='customizations-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='customizations-list-adapter-card'>
								<label className='customizations-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='customizations-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as CustomizationModel;
						return <div className='customizations-list-adapter'>
							<label className='customizations-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='customizations-list-adapter-card'>
								<label className='customizations-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddCus(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>
			<div className='customizations-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='customizations-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddCus && <label className='customizations-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='customizations-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='customizations-detail-perc'>{data[selItemIndex].name}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='customizations-detail-perc'>{data[selItemIndex].levels.map(v => v.levelName).toString().replaceAll(',', ', ')}</label>
					</div>}
					{isAddCus && <div>
						<TextField ref={name} isLoading={isListLoading}
							label={'Enter the new pharse'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={10} isAutoResize={false}></Space>
						<div className='customizations-container' style={{padding: '1%', height: '200px'}}>
							<ListSlots ref={answs} isLoading={isListLoading} title={'Enter options'} placeHold={'I.e: Hot'}></ListSlots>
						</div>
						<Space size={20} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Customizations;
