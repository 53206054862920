class Delete {
	svgRaw = `<svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M24.9028 5.82296C22.8064 5.61462 20.71 5.45837 18.6006 5.34117V5.32815L18.3141 3.6354C18.1188 2.43744 17.8323 0.640518 14.7853 0.640518H11.3738C8.3398 0.640518 8.05334 2.35932 7.845 3.62238L7.57155 5.28909C6.36058 5.36722 5.14961 5.44534 3.93864 5.56254L1.28231 5.82296C0.735419 5.87504 0.344783 6.35683 0.396868 6.8907C0.448953 7.42457 0.917716 7.8152 1.46461 7.76312L4.12093 7.5027C10.9441 6.82559 17.8193 7.08602 24.7205 7.77614H24.8247C25.3195 7.77614 25.7492 7.39853 25.8013 6.8907C25.8404 6.35683 25.4497 5.87504 24.9028 5.82296Z" fill="#292D32"/>
	<path d="M22.507 9.61213C22.1945 9.2866 21.7648 9.1043 21.3221 9.1043H4.86331C4.42059 9.1043 3.97787 9.2866 3.67838 9.61213C3.37889 9.93766 3.20961 10.3804 3.23566 10.8361L4.04297 24.1959C4.1862 26.1751 4.3685 28.6491 8.9129 28.6491H17.2725C21.8169 28.6491 21.9993 26.1881 22.1424 24.1959L22.9497 10.8491C22.9757 10.3804 22.8065 9.93766 22.507 9.61213ZM15.2542 22.1255H10.9181C10.3843 22.1255 9.94158 21.6828 9.94158 21.1489C9.94158 20.615 10.3843 20.1723 10.9181 20.1723H15.2542C15.788 20.1723 16.2308 20.615 16.2308 21.1489C16.2308 21.6828 15.788 22.1255 15.2542 22.1255ZM16.3479 16.917H9.83741C9.30354 16.917 8.86082 16.4743 8.86082 15.9404C8.86082 15.4066 9.30354 14.9638 9.83741 14.9638H16.3479C16.8818 14.9638 17.3245 15.4066 17.3245 15.9404C17.3245 16.4743 16.8818 16.917 16.3479 16.917Z" fill="#292D32"/>
	</svg>
	`;

	replaceColor = '#292D32';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}

	public red() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FF0000'))}`;
	}
}

export default Delete;
