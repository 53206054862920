import React, {useEffect, useRef, useState} from 'react';
import './Categories.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import CategoryService from '../../services/categories';
import {type CategoryModel} from '../../models/categories';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Categories = () => {
	const [isAddCat, setAddCat] = useState<boolean>(false);
	const [data, setData] = useState<CategoryModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchTax();
	}, []);

	async function fetchTax() {
		await new CategoryService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddCat(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = name.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid) {
			setListLoading(true);
			await new CategoryService().create(name.current!.getValue()!).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchTax();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				name.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddCat) ? 'center' : 'start',
	};

	return (
		<div className='Categories'>
			<div className='categories-left-panel'>
				<div className='categories-left-tools'>
					<label className='categories-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Category'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='categories-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='categories-list-adapter'>
							<label className='categories-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='categories-list-adapter-card'>
								<label className='categories-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='categories-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as CategoryModel;
						return <div className='categories-list-adapter'>
							<label className='categories-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='categories-list-adapter-card'>
								<label className='categories-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddCat(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>
			<div className='categories-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='categories-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddCat && <label className='categories-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='categories-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='categories-detail-perc'>{data[selItemIndex].name}</label>
					</div>}
					{isAddCat && <div>
						<TextField ref={name} isLoading={isListLoading}
							label={'Enter the new category'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Categories;
