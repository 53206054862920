/* eslint-disable @typescript-eslint/naming-convention */
import {type ReportHandler} from 'web-vitals';

const reportWebVitals = async (onPerfEntry?: ReportHandler) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		try {
			const webVitals = await import('web-vitals');
			const {getCLS, getFID, getFCP, getLCP, getTTFB} = webVitals;

			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		} catch (error) {
			// Handle the error if needed
			// console.error('Error loading web-vitals:', error);
		}
	}
};

export default reportWebVitals;
