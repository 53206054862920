class Upload {
	svgRaw = `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M24.0743 10.9538H20.209C17.0393 10.9538 14.458 8.37251 14.458 5.20272V1.33746C14.458 0.601858 13.8561 0 13.1205 0H7.44966C3.33028 0 0 2.67492 0 7.44966V19.2996C0 24.0743 3.33028 26.7492 7.44966 26.7492H17.9621C22.0815 26.7492 25.4118 24.0743 25.4118 19.2996V12.2913C25.4118 11.5557 24.8099 10.9538 24.0743 10.9538ZM12.0773 15.4209C11.8767 15.6215 11.6225 15.7152 11.3684 15.7152C11.1143 15.7152 10.8602 15.6215 10.6596 15.4209L9.69659 14.458V20.0619C9.69659 20.6103 9.24186 21.065 8.6935 21.065C8.14514 21.065 7.6904 20.6103 7.6904 20.0619V14.458L6.72743 15.4209C6.33957 15.8088 5.69759 15.8088 5.30972 15.4209C4.92186 15.0331 4.92186 14.3911 5.30972 14.0032L7.98464 11.3283C8.07827 11.248 8.17189 11.1812 8.27889 11.1277C8.30563 11.1143 8.34576 11.1009 8.37251 11.0876C8.45276 11.0608 8.533 11.0474 8.62663 11.0341H8.73362C8.84062 11.0341 8.94762 11.0608 9.05461 11.1009H9.08136C9.18836 11.1411 9.29536 11.2213 9.3756 11.3015C9.38898 11.3149 9.40235 11.3149 9.40235 11.3283L12.0773 14.0032C12.4651 14.3911 12.4651 15.0331 12.0773 15.4209Z" fill="#2273D8"/>
	<path d="M19.9679 9.10876C21.2385 9.12214 23.004 9.12214 24.5153 9.12214C25.2777 9.12214 25.6789 8.22604 25.1439 7.69105C23.218 5.75173 19.7673 2.26096 17.7879 0.281517C17.2395 -0.266842 16.2899 0.107647 16.2899 0.87V5.53774C16.2899 7.49043 17.9484 9.10876 19.9679 9.10876Z" fill="#2273D8"/>
	</svg>`;

	replaceColor = '#2273D8';

	public normal() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw)}`;
	}

	public white() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgRaw.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default Upload;
