class Arrow {
	svgArUp = `<svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7059 1.25226e-06C13.732 1.25226e-06 14.7582 0.395802 15.5351 1.17274L25.0929 10.7305C25.518 11.1556 25.518 11.8593 25.0929 12.2844C24.6678 12.7095 23.9641 12.7095 23.539 12.2844L13.9812 2.72662C13.2776 2.02298 12.1342 2.02298 11.4305 2.72662L1.87269 12.2844C1.44758 12.7095 0.743933 12.7095 0.318815 12.2844C-0.106304 11.8593 -0.106304 11.1556 0.318815 10.7305L9.87665 1.17274C10.6536 0.395802 11.6797 1.25226e-06 12.7059 1.25226e-06Z" fill="#292D32"/>
    </svg>`;

	svgArDo = `<svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7059 12.6032C11.6797 12.6032 10.6536 12.2074 9.87663 11.4305L0.318839 1.87272C-0.10628 1.4476 -0.10628 0.743957 0.318839 0.318839C0.743957 -0.10628 1.4476 -0.10628 1.87272 0.318839L11.4305 9.87661C12.1342 10.5803 13.2776 10.5803 13.9812 9.87661L23.539 0.318839C23.9642 -0.10628 24.6678 -0.10628 25.0929 0.318839C25.518 0.743957 25.518 1.4476 25.0929 1.87272L15.5351 11.4305C14.7582 12.2074 13.732 12.6032 12.7059 12.6032Z" fill="#292D32"/>
    </svg>`;

	replaceColor = '#292D32';

	public normalUp() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgArUp)}`;
	}

	public normalDown() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgArDo)}`;
	}

	public whiteUp() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgArUp.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}

	public whiteDown() {
		return `data:image/svg+xml;utf8,${encodeURIComponent(this.svgArDo.replaceAll(this.replaceColor, '#FFF3F3'))}`;
	}
}

export default Arrow;
