import React, {useEffect, useRef, useState} from 'react';
import './Menus.css';
import Space from '../../components/Space/Space';
import OutlineButton from '../../components/OutlineButton/OutlineButton';
import ListView from '../../components/ListView/ListView';
import TextField, {type TextFieldHandle} from '../../components/TextField/TextField';
import Button, {type ButtonHandle} from '../../components/Button/Button';
import TextFieldInputTypes from '../../utils/textfield/fieldtypes';
import SnackRibbon, {type SnackRibbonHandle} from '../../components/SnackRibbon/SnackRibbon';
import MenusService from '../../services/menus';
import {type MenusModel} from '../../models/menus';

type CustomStyleType = {
	'--justifyContent': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const Menus = () => {
	const [isAddMenu, setAddMenu] = useState<boolean>(false);
	const [data, setData] = useState<MenusModel[]>([]);
	const [selItemIndex, setItemIndex] = useState<number>(-1);
	const [isListLoading, setListLoading] = useState<boolean>(true);

	const snackBarRibbon = useRef<SnackRibbonHandle>(null);
	const name = useRef<TextFieldHandle>(null);
	const onSaveBtn = useRef<ButtonHandle>(null);

	useEffect(() => {
		void fetchMenu();
	}, []);

	async function fetchMenu() {
		await new MenusService().get().then(val => {
			if (val.hasError) {
				snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
			} else {
				setData(val.res!.data!);
			}
		}).finally(() => {
			setListLoading(false);
		});
	}

	const handleOnAddlick = () => {
		setAddMenu(true);
		setItemIndex(-1);
	};

	const onSave = async () => {
		const field1 = name.current!.validate();
		if (!field1.isValid) {
			snackBarRibbon.current!.trigger(true, field1.msg);
			onSaveBtn.current!.complete();
		}

		if (field1.isValid) {
			setListLoading(true);
			await new MenusService().create(name.current!.getValue()!).then(async val => {
				if (val.hasError) {
					snackBarRibbon.current!.trigger(val.hasError, val.errorMsg!);
				} else {
					await fetchMenu();

					snackBarRibbon.current!.trigger(false, val.res!.message);
				}

				onSaveBtn.current!.complete();
				name.current!.clearInput();
			});
		}
	};

	const customStyle: ExtendedDivStyle = {
		'--justifyContent': (selItemIndex === -1 && !isAddMenu) ? 'center' : 'start',
	};

	return (
		<div className='Menus'>
			<div className='Menus-left-panel'>
				<div className='Menus-left-tools'>
					<label className='Menus-count-label'>{`Total : ${data.length}`}</label>
					<OutlineButton onClick={handleOnAddlick} label={'Add Menu'}></OutlineButton>
				</div>
				{isListLoading ? (<div className='Menus-left-list'>
					<ListView dummy={5} adapter={function (i, item): JSX.Element {
						return <div className='Menus-list-adapter'>
							<label className='Menus-list-adapter-index-load'>00</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Menus-list-adapter-card'>
								<label className='Menus-list-adapter-update-load'>{'Tax Percentage'}</label>
							</div>
						</div>;
					}} selItemIndex={-1} adapterHeight={40} />
				</div>) : (<div className='Menus-left-list'>
					<ListView data={data} adapter={function (i, item): JSX.Element {
						const itemData = item as MenusModel;
						return <div className='Menus-list-adapter'>
							<label className='Menus-list-adapter-index'>{i + 1}</label>
							<Space size={5} isAutoResize={true}></Space>
							<div className='Menus-list-adapter-card'>
								<label className='Menus-list-adapter-perc'>{itemData.name}</label>
							</div>
						</div>;
					}} selItemIndex={selItemIndex} onSelectItem={i => {
						setAddMenu(false);
						setItemIndex(i);
					}} adapterHeight={40} />
				</div>)}
			</div>
			<div className='Menus-right-panel'>
				<SnackRibbon ref={snackBarRibbon}></SnackRibbon>
				<div className='Menus-right-content' style={customStyle}>
					{selItemIndex === -1 && !isAddMenu && <label className='Menus-right-empty-content'>No items selected</label>}
					{selItemIndex !== -1 && <div>
						<label className='Menus-detail-no'>{`No : ${selItemIndex + 1}`}</label>
						<Space size={5} isAutoResize={true}></Space>
						<label className='Menus-detail-perc'>{data[selItemIndex].name}</label>
					</div>}
					{isAddMenu && <div>
						<TextField ref={name} isLoading={isListLoading}
							label={'Enter the new menu'}
							inputType={TextFieldInputTypes.name}></TextField>
						<Space size={5} isAutoResize={false}></Space>
						<Button ref={onSaveBtn} label={'Save'} onClick={onSave}></Button>
					</div>}
				</div>
			</div>
		</div >
	);
};

export default Menus;
