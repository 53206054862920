// Const base = 'https://localhost:4040/api';
const base = 'https://api.tasteofindia.nz/api';

const endpoints = {
	basews: 'https://api.tasteofindia.nz',
	register: `${base}/de-assistant/send-otp`,
	verifyOtp: `${base}/de-assistant/verify-otp`,
	userDetails: `${base}/de-assistant/user-details`,
	restaurants: `${base}/de-assistant/restaurants`,
	materials: `${base}/de-assistant/materials`,
	material: `${base}/de-assistant/material`,
	addons: `${base}/de-assistant/addons`,
	addon: `${base}/de-assistant/addon`,
	customizations: `${base}/de-assistant/customizations`,
	customization: `${base}/de-assistant/customization`,
	menus: `${base}/de-assistant/menus`,
	menu: `${base}/de-assistant/menu`,
	categories: `${base}/de-assistant/categories`,
	category: `${base}/de-assistant/category`,
	coalitions: `${base}/de-assistant/coalitions`,
	taxes: `${base}/de-assistant/taxes`,
	conditions: `${base}/de-assistant/conditions`,
	fooditem: `${base}/de-assistant/food-item`,
	fooditems: `${base}/de-assistant/food-items`,
	logout: `${base}/de-assistant/logout`,
};

export default endpoints;
