import React, {useState, useEffect} from 'react';
import Auth from '../pages/Auth/Auth';
import RouteNames from './RouteNames';
import Dashboard from '../pages/Dashboard/Dashboard';
import {AuthProvider} from '../context/authContext';
import {RestaurantProvider} from '../context/restContext';

type RouterComponent = {
	navigateTo?: (path: string) => void;
} & React.FC;

const Router: RouterComponent = () => {
	const [route, setRoute] = useState<string>('');

	useEffect(() => {
		// Check token exists or not if
		// exists then set the route to dashboard, otherwise
		// set the route to auth
		if (!localStorage.getItem('AUT-GENERAL-MANAGER')) {
			setRoute(RouteNames.auth);
		}

		if (localStorage.getItem('AUT-GENERAL-MANAGER')) {
			setRoute(RouteNames.dashboard);
		}
	}, []); // Empty dependency array ensures this effect runs only once

	Router.navigateTo = path => {
		setRoute(path);
	};

	// Render the component based on the current route
	const renderComponent = () => {
		switch (route) {
			case RouteNames.dashboard:
				return <AuthProvider>
					<RestaurantProvider>
						<Dashboard />
					</RestaurantProvider>
				</AuthProvider>;
			case RouteNames.auth:
				return <Auth />;
			default:
				// Return <NotFound />;
				return <div />;
		}
	};

	return <div>{renderComponent()}</div>;
};

export default Router;
