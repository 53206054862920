import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import './SnackRibbon.css';

type Props = Record<string, unknown>;

type SnackRibbonProps = {
	show: boolean;
	message: string;
	isError: boolean;
} & Props;

export type SnackRibbonHandle = {
	trigger: (hasError: boolean, msg: string) => void;
};

const SnackRibbon = forwardRef<SnackRibbonHandle, Props>((props, ref) => {
	const [showSnackRibbon, setShowSnackRibbon] = useState<SnackRibbonProps['show']>(false);
	const [message, setMessage] = useState<SnackRibbonProps['message']>('');
	const [isError, setIsError] = useState<SnackRibbonProps['isError']>(true);

	// UseEffect(() => {
	// 	let timer: NodeJS.Timeout;
	// 	if (showSnackRibbon) {
	// 		timer = setTimeout(() => {
	// 			setShowSnackRibbon(false);
	// 			setMessage('');
	// 		}, 2000);
	// 	}

	// 	return () => {
	// 		clearTimeout(timer);
	// 	};
	// }, [showSnackRibbon]);

	useEffect(() => {
		let timer: NodeJS.Timeout | undefined;

		if (showSnackRibbon) {
			timer = setTimeout(() => {
				setShowSnackRibbon(false);
				setMessage('');
			}, 2000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [showSnackRibbon]);

	useImperativeHandle(ref, () => ({
		trigger(hasError: boolean, msg?: string) {
			if (!showSnackRibbon && msg) {
				setShowSnackRibbon(true);
				setIsError(hasError);
				setMessage(msg);
			}
		},
	}));

	const sbClassName = `snack-ribbon ${isError ? 'invalid' : 'valid'}`;
	const sblClassName = `snack-ribbon-label ${isError ? 'invalid' : 'valid'}`;

	return showSnackRibbon ? (
		<div className={sbClassName}>
			<label className={sblClassName}>{message}</label>
		</div>
	) : null;
});

SnackRibbon.displayName = 'SnackRibbon';

export default SnackRibbon;
