import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import type {TaxModel} from '../models/tax';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';

type ServerMessage = {
	message: string;
};

class TaxService {
	get = async (): Promise<ServiceResponse<ResponseData<TaxModel[]>>> => {
		try {
			const response = await axios.get<ResponseData<TaxModel[]>>(endpoints.taxes,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
						'Content-Type': 'application/json',
					},
				});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default TaxService;
