import RouteNames from '../routes/RouteNames';
import Router from '../routes/Router';

const unauthorized = (msg: string) => {
	if (msg === 'Unauthorized') {
		localStorage.clear();
		Router.navigateTo!(RouteNames.auth);
	}
};

export default unauthorized;
