import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './DotStatus.css';

type Props = {
	isActive: boolean;
	size: string;
};

export type DotStatusHandle = Record<string, unknown>;

type CustomStyleType = {
	'--color': string;
	'--size': string;
};

type ExtendedDivStyle = React.CSSProperties & CustomStyleType;

const DotStatus = forwardRef<DotStatusHandle, Props>((props, ref) => {
	const btnRef = useRef<HTMLButtonElement>(null);
	const [isLoading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		// Complete() {
		// 	setLoading(false);
		// 	return isLoading;
		// },
	}));

	const customStyle: ExtendedDivStyle = {
		'--color': props.isActive ? 'rgb(0, 212, 0)' : 'tomato',
		'--size': props.size,
	};

	return (
		<div className='DotStatus' style={customStyle}></div>
	);
});

DotStatus.displayName = 'DotStatus';

export default DotStatus;
