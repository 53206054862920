import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';
import './CheckBox.css';

type Props = {
	title: string;
	onClick?: (c: boolean) => void;
	initVal?: boolean;
	// Overwrite the storing values
	ovrVal?: boolean;
	isLoading?: boolean;
};

export type CheckBoxHandle = {
	getValue: () => boolean;
};

const CheckBox = forwardRef<CheckBoxHandle, Props>((props, ref) => {
	const chbRef = useRef<HTMLInputElement>(null);
	const [isChecked, setIsChecked] = useState(props.initVal ?? false);

	const handleCheckboxChange = () => {
		setIsChecked(!isChecked);
		if (props.onClick) {
			props.onClick(!isChecked);
		}
	};

	useImperativeHandle(ref, () => ({
		getValue() {
			if (props.ovrVal) {
				return props.ovrVal;
			}

			return isChecked;
		},
	}));

	return (
		<div className='checkbox'>
			<label className='checkbox-title' onClick={handleCheckboxChange}>{props.title}</label>
			<input
				ref={chbRef}
				type='CheckBox'
				checked={props.ovrVal ?? isChecked}
				onChange={handleCheckboxChange}
				disabled={props.isLoading}
			/>
		</div>

	);
});

CheckBox.displayName = 'CheckBox';

export default CheckBox;
