import axios, {type AxiosError} from 'axios';
import endpoints from '../utils/endpoints';
import messages from '../utils/messages';
import type {SendOtpModel, VerifyOtpModel, AddUserDetailsModel, UserDetailsModel} from '../models/auth';
import {type ResponseData, type ServiceResponse} from '../models/response';
import {serviceError, serviceSuccess} from '../utils/serviceHandler';

type ServerMessage = {
	message: string;
};

type VerifyOtpProps = {
	provider: string; key: string; otp: number;
};

type UserDetailsProps = {
	firstName: string; lastName: string; key: string;
};

class AuthService {
	sendOtp = async (provider: string): Promise<ServiceResponse<ResponseData<SendOtpModel>>> => {
		try {
			const response = await axios.post<ResponseData<SendOtpModel>>(endpoints.register, {provider});
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	verifyOtp = async (props: VerifyOtpProps): Promise<ServiceResponse<ResponseData<VerifyOtpModel>>> => {
		const {provider, key, otp} = props;
		try {
			const response = await axios.post<ResponseData<VerifyOtpModel>>(endpoints.verifyOtp, {provider, key, otp});
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	addUserDetails = async (props: UserDetailsProps): Promise<ServiceResponse<ResponseData<AddUserDetailsModel>>> => {
		const {firstName, lastName, key} = props;

		try {
			const response = await axios.post<ResponseData<AddUserDetailsModel>>(endpoints.userDetails,
				{key, firstName, lastName},
			);

			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	userDetails = async (): Promise<ServiceResponse<ResponseData<UserDetailsModel>>> => {
		try {
			const response = await axios.get<ResponseData<UserDetailsModel>>(endpoints.userDetails, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
					'Content-Type': 'application/json',
				},
			});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};

	logout = async (): Promise<ServiceResponse<ResponseData<undefined>>> => {
		try {
			const response = await axios.get<ResponseData<undefined>>(endpoints.logout, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('AUT-GENERAL-MANAGER')!}`,
					'Content-Type': 'application/json',
				},
			});
			await serviceSuccess();
			return {hasError: false, res: response.data};
		} catch (error) {
			const axiosError = error as AxiosError<ServerMessage>;
			if (axiosError.response?.data?.message) {
				await serviceError(axiosError.response?.data?.message);

				// Access the error message from the server response
				const errorMessage = axiosError.response.data.message;
				return {hasError: true, errorMsg: errorMessage};
			}

			return {hasError: true, errorMsg: messages.Ise};
		}
	};
}

export default AuthService;
